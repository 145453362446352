<template>
  <div class="Booking" id="Booking">
    <div class="list-header">
      <div
        class="openForm"
        @click="clickCloseSearch()"
        v-show="SearchType == 1 && !closeSearch"
      >
        <svg-icon icon-class="filter"></svg-icon>{{ $t("lang.BText1") }}
      </div>
      <div
        class="header-left"
        :class="{
          open: closeSearch,
          close: openSearch,
          smallHeader: SearchType == 1,
        }"
        v-show="(SearchType == 1 && closeSearch) || SearchType == 2"
      >
        <!-- <div
          class="openForm"
          @click="clickCloseSearch()"
          v-show="SearchType == 1 && closeSearch"
        >
          <svg-icon icon-class="filter"></svg-icon>Filter
        </div> -->
        <div class="form">
          <div class="search-item">
            <div class="form-item-title">ID & Reference No.</div>
            <el-input class="insurance-input" v-model="searchForm.bookingNo">
            </el-input>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.BText2") }}</div>
            <el-select
              class="date-card-select"
              v-model="searchForm.bookingStatus"
              :placeholder="$t('lang.BText80')"
            >
              <el-option
                v-for="item in bookingStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.BText106") }}</div>
            <el-cascader
              v-model="searchForm.bookingTypeArr"
              :placeholder="$t('lang.BText80')"
              :options="quotationTypeList"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.dictLabel }}</span>
              </template>
            </el-cascader>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.BText107") }}</div>
            <el-select
              class="step1-select"
              v-model="searchForm.originPort"
              :placeholder="$t('lang.BText80')"
              filterable
              remote
              clearable
              :remote-method="findPortsList"
              :loading="selectLoading"
              ref="originPort"
            >
              <el-option
                v-for="item in PortsList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.BText17") }}</div>
            <el-input class="insurance-input" v-model="searchForm.shipper">
            </el-input>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.BText3") }}</div>
            <el-input class="insurance-input" v-model="searchForm.consignee">
            </el-input>
          </div>

          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.QText21") }}</div>
            <el-date-picker
              v-model="cargoReadyDate"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.NText300')"
              :end-placeholder="$t('lang.NText209')"
              value-format="yyyy-MM-dd"
              :clearable="false"
            >
            </el-date-picker>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.BText5") }}</div>
            <el-date-picker
              v-model="createDate"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.NText300')"
              :end-placeholder="$t('lang.NText209')"
              value-format="yyyy-MM-dd"
              :clearable="false"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="search-button">
          <div class="refresh-button" @click="clearSearch()">
            <svg-icon icon-class="refresh"></svg-icon>
          </div>
          <el-button class="searchbutton" @click="query">{{
            $t("lang.BText108")
          }}</el-button>
          <svg-icon
            v-show="!closeSearch"
            class="icon"
            icon-class="downTriangle"
            @click="clickCloseSearch()"
          ></svg-icon>
          <svg-icon
            v-show="closeSearch"
            class="icon"
            icon-class="upTriangle"
            @click="clickOpenSearch()"
          ></svg-icon>
        </div>
      </div>
      <div class="header-right" v-show="!(SearchType == 1 && closeSearch)">
        <el-button @click="clickDraft()">{{ $t("lang.BText6") }}</el-button>
        <el-button @click="clickTemplate()">{{ $t("lang.BText7") }}</el-button>
        <el-button
          class="create"
          :loading="creatLoading"
          @click="createBooking()"
          >{{ $t("lang.BText75") }}</el-button
        >
      </div>
    </div>
    <dwlist
      :url="source.booking_api.bookingList"
      :query-params="searchForm"
      ref="postsList"
      v-loading="editLoading"
    >
      <el-table-column prop="bookingNo" label="ID" min-width="140%">
      </el-table-column>
      <el-table-column prop="referenceNo" label="Reference No." width="150px">
      </el-table-column>
      <el-table-column
        prop="summry"
        :label="$t('lang.BText4')"
        min-width="190%"
      >
        <template slot-scope="scope">
          <div class="port">
            {{ scope.row.originPortName
            }}<svg-icon icon-class="right" style="color: #299be4"></svg-icon
            >{{ scope.row.destinationDischargePortName }}
          </div>
          <div>{{ scope.row.bNo }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="bookingType" :label="$t('lang.BText8')">
        <template slot-scope="scope">
          <div class="FCL" v-show="scope.row.bookingType == 'FCL'">
            <el-tooltip
              class="item"
              effect="dark"
              content="Ocean Freight"
              placement="bottom"
            >
              <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
            </el-tooltip>
            <div class="line"></div>
            <el-tooltip
              class="item"
              effect="dark"
              content="FCL"
              placement="bottom"
            >
              <svg-icon class="typeIcon" icon-class="FCL"></svg-icon>
            </el-tooltip>
          </div>
          <div class="LCL" v-show="scope.row.bookingType == 'LCL'">
            <el-tooltip
              class="item"
              effect="dark"
              content="Ocean Freight"
              placement="bottom"
            >
              <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
            </el-tooltip>
            <div class="line"></div>
            <el-tooltip
              class="item"
              effect="dark"
              content="LCL"
              placement="bottom"
            >
              <svg-icon class="typeIcon" icon-class="LCL"></svg-icon>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bookingStatus" :label="$t('lang.BText9')">
        <template slot-scope="scope">
          <div v-if="scope.row.bookingStatus == '10'">
            {{ $t("lang.BText10") }}
          </div>
          <div
            v-if="
              scope.row.bookingStatus == '201' ||
              scope.row.bookingStatus == '202' ||
              scope.row.bookingStatus == '101'
            "
          >
            {{ $t("lang.BText11") }}
          </div>
          <div
            v-if="
              scope.row.bookingStatus == '30' || scope.row.bookingStatus == '40'
            "
          >
            {{ $t("lang.BText12") }}
          </div>
          <!-- <div v-if="scope.row.bookingStatus == '40'">{{ $t("lang.BText13") }}</div> -->
          <div v-if="scope.row.bookingStatus == '50'">
            {{ $t("lang.BText14") }}
          </div>
          <div v-if="scope.row.bookingStatus == '70'">
            {{ $t("lang.BText15") }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="CargoInfo"
        :label="$t('lang.BText16')"
        min-width="160%"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.containerType">
            <div
              v-for="(item, index) in JSON.parse(scope.row.containerType)"
              :key="index"
            >
              {{ item.containerTypOne }} * {{ item.containerTypTwo }}
            </div>
          </div>
          <div class="tot">
            {{ scope.row.totalVolume }} {{ scope.row.totalVolumeUnit }} |
            {{ scope.row.totalWeight }} {{ scope.row.totalWeightUnit }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="cargoReadyDay"
        :label="$t('lang.QText21')"
        min-width="120%"
      >
        <template slot-scope="scope">
          <span v-if="$i18n.locale == 'en_us' && scope.row.cargoReadyDay">{{
            zhDate_to_ehDate(scope.row.cargoReadyDay)
          }}</span
          ><span v-else-if="scope.row.cargoReadyDay">{{
            scope.row.cargoReadyDay
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="shipper" :label="$t('lang.BText17')">
        <template slot-scope="scope">
          <div class="shipper">
            {{ scope.row.shipper }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="consignee" :label="$t('lang.BText3')">
        <template slot-scope="scope">
          <div class="shipper">
            {{ scope.row.consignee }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        :label="$t('lang.BText5')"
        min-width="120%"
      >
        <template slot-scope="scope">
          <span v-if="$i18n.locale == 'en_us'">{{
            zhDate_to_ehDate(scope.row.createDate)
          }}</span
          ><span v-else>{{ scope.row.createDate }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" min-width="160%">
        <template slot-scope="scope">
          <div class="buttonList">
            <el-button
              class="samll-list-primary"
              :disabled="scope.row.type == 'LCL' || scope.row.bookingLock == 1"
              @click="clickEdit(scope.row)"
              >{{ $t("lang.QText77") }}</el-button
            >
            <el-popover placement="bottom" trigger="hover">
              <div class="moreList">
                <div class="list-button-item" @click="clickView(scope.row)">
                  {{ $t("lang.BText18") }}
                </div>
                <el-button
                  :disabled="
                    (scope.row.bookingStatus != '30' &&
                      scope.row.bookingStatus != '40') ||
                    scope.row.userAuthorization == 0
                  "
                  type="text"
                  class="list-button-item"
                  @click="click_shipping(scope.row)"
                >
                  {{ $t("lang.BText70") }}
                </el-button>
                <div
                  v-if="scope.row.bookingStatus == '10'"
                  class="list-button-item"
                  @click="click_CancelBooking(scope.row)"
                >
                  {{ $t("lang.QText79") }}
                </div>
                <!-- <div class="list-button-item" @click="history(scope.row)">
                  {{ $t("lang.QText87") }}
                </div> -->
              </div>
              <el-button slot="reference" class="samll-list-info"
                >{{ $t("lang.BText109")
                }}<svg-icon class="icon" icon-class="downTriangle"></svg-icon
              ></el-button>
            </el-popover>

            <!-- <el-button class="chat-icon" @click="chatIconClick(scope.row)">
              <svg-icon icon-class="chat"></svg-icon>
            </el-button> -->
          </div>
        </template>
      </el-table-column>
    </dwlist>
    <el-dialog
      :visible.sync="reviewFlag"
      v-if="reviewFlag"
      class="createDialog dw-dialog"
      width="70%"
      @close="closeDialog"
      id="bookingDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ $t("lang.BText110") }}</div>
          <div
            class="row2"
            v-if="
              bookingViewData.quotation && bookingViewData.quotation.quotationNo
            "
          >
            {{ $t("lang.BText111") }}:
            {{ bookingViewData.quotation.quotationNo }}
          </div>
        </div>
        <div class="titleDate">
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.BText112") }}:</div>
            <div class="date">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(bookingViewData.updateDate)
              }}</span
              ><span v-else>{{ bookingViewData.updateDate }}</span>
            </div>
          </div>
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.BText113") }}:</div>
            <div class="date2" v-if="bookingViewData.expirationTime">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(bookingViewData.expirationTime)
              }}</span
              ><span v-else>{{ bookingViewData.expirationTime }}</span>
            </div>
          </div>
        </div>
        <div class="close" @click="openCheckBox()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <booking-dialog
          :bookingView="bookingViewData"
          :toSendMsgObj="toSendMsgObj"
          :type="'3'"
        ></booking-dialog>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showFillIn"
      :visible.sync="showFillIn"
      width="40%"
      class="fillInDialog"
      v-loading="fillInLoading"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="fillInHeader">
        <div class="headerTitle">{{ $t("lang.BText114") }}</div>
        <div class="close" @click="showFillIn = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <fill-in-list
        :defaultTab="defaultTab"
        @fillInBooking="fillInBooking"
        @Manage="Manage"
      ></fill-in-list>
    </el-dialog>
    <el-dialog
      v-if="createFlag"
      :visible.sync="createFlag"
      class="createDialog dw-dialog"
      width="70%"
      @close="closeDialog"
      id="createbookingDialog"
      ref="createbookingDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ dialogTitle }}</div>
          <div class="row2" v-if="submitBooking.bookingNo">
            ID：<span style="color: #fff">{{ submitBooking.bookingNo }}</span>
          </div>
        </div>
        <div class="titleDate" v-if="submitBooking.bookingNo">
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.BText112") }}:</div>
            <div class="date">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(submitBooking.updateDate)
              }}</span
              ><span v-else>{{ submitBooking.updateDate }}</span>
            </div>
          </div>
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.BText113") }}:</div>
            <div class="date2">
              <span v-if="$i18n.locale == 'en_us'">{{
                submitBooking.deliveryData?zhDate_to_ehDate(submitBooking.deliveryData):''
              }}</span
              ><span v-else>{{ submitBooking.deliveryData?submitBooking.deliveryData:'' }}</span>
            </div>
          </div>
        </div>

        <div class="close" @click="openCheckBox()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <link-quo
          v-if="linkQuoFlag"
          @fillInBooking="fillInBooking"
          @Previous="Previous"
          @Next="Next"
        ></link-quo>
        <create-booking-index
          v-if="quotationMainType == '' && create"
          @chooseType="clickType"
          @clickShowFillIn="clickShowFillIn"
        ></create-booking-index>
        <create-booking
          @clickReview="clickReview"
          v-if="quotationMainType !== '' && create"
          :quotationMainType="quotationMainType"
          :quotationType="quotationType"
          @Previous="Previous2"
          @next="toTop"
        ></create-booking>
        <reviewBooking
          v-if="review"
          :bookingData="submitBooking"
          @clickPrevious="clickPrevious"
          @editBooking="reviewToEdit"
          @SaveSuccess="SaveSuccess"
        ></reviewBooking>
        <booking-edit
          @clickReview="clickReview"
          :bookingData="submitBooking"
          v-if="edit"
          @cancelEdit="cancelEdit"
        ></booking-edit>
      </div>
    </el-dialog>
    <dw-check-box
      :showCheckBox="showDelect"
      @Cancel="Cancel"
      @Save="closeDialog"
      :CancelButton="$t('lang.QText79')"
      :primaryButton="$t('lang.NText192')"
    >
      {{ $t("lang.BText19") }}
    </dw-check-box>
    <dw-check-box
      :showCheckBox="showShipping"
      @Cancel="Cancel"
      @Save="shippingAuthorize"
      :CancelButton="$t('lang.QText79')"
      :primaryButton="$t('lang.yes')"
    >
      {{ $t("lang.BText115") }}
    </dw-check-box>
    <dw-check-box
      :showCheckBox="showCancel"
      @Cancel="Cancel"
      @Save="CancelBooking"
      :primaryButton="$t('lang.yes')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.BText116") }}
    </dw-check-box>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import test from "../../test/test";
import createBooking from "./create.vue";
import createBookingIndex from "./create-index.vue";
import reviewBooking from "./review.vue";
import bookingEdit from "./edit.vue";
import FillInList from "./fillInList.vue";
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
import Template from "./template.vue";
import BookingDialog from "./bookingDialog.vue";
import utils from "../../utils/util";
import LinkQuo from "./linkQuo.vue";
export default {
  components: {
    dwlist,
    createBooking,
    createBookingIndex,
    reviewBooking,
    bookingEdit,
    FillInList,
    dwCheckBox,
    Template,
    BookingDialog,
    LinkQuo,
  },
  data() {
    return {
      isDrawer: false,
      // srcIndex:
      //   "https://1996571.s4.udesk.cn/im_client/?web_plugin_id=30982&language=zh-cn",
      srcIndex:
        "https://1996571.s4.udesk.cn/im_client/?web_plugin_id=30982&language=en-us",
      listData: test.listData,
      searchForm: {
        bookingStatus: "100",
      },
      toSendMsgObj: {},
      selectList: [],
      openSearch: false,
      closeSearch: false,
      createFlag: false,
      dialogTitle: "",
      review: false,
      edit: false,
      bookingId: null,
      showFillIn: false,
      showDelect: false,
      quotationMainType: "",
      quotationType: "",
      value1: "",
      submitBooking: {},
      reviewFlag: false,
      defaultTab: "0",
      create: false,
      fillInLoading: false,
      bookingViewData: {},
      quotationTypeObj: {},
      quotationTypeList: [],
      PortsList: [],
      selectLoading: false,
      bookingStatus: [],
      cargoReadyDate: [],
      createDate: [],
      showMore: false,
      linkQuoFlag: false,
      userInfo: {},
      thisRow: {},
      showShipping: false,
      showCancel: false,
      editLoading: false,
      creatLoading: false,
      crmEdocId: null,
    };
  },
  computed: {
    SearchType() {
      if (window.innerWidth < 1660) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  created() {
    this.findPortsList();
    this.$session("quotationType").get((value) => {
      this.quotationTypeList = value;
      this.quotationTypeList.forEach((item) => {
        item.label = item.dictLabel;
        item.value = item.dictValue;
        this.quotationTypeObj[item.dictValue] = item.dictLabel;
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            item2.label = item2.dictLabel;
            item2.value = item2.dictValue;
            this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
          });
        }
      });
    });
    this.$session("bookingStatus").get((value) => {
      this.bookingStatus = value;
    });
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
    if (this.$route.params.editFlag) {
      this.clickEdit();
    }
    if (this.$route.params.createFlag) {
      this.createBooking();
    }
    if (this.$route.params.fillInFormQ) {
      this.fillInBooking(this.$route.params);
    }
    if (this.$route.params.dOrTId) {
      this.fillInBooking(this.$route.params);
    }
  },
  methods: {
    query() {
      if (this.cargoReadyDate && this.cargoReadyDate.length > 1) {
        this.searchForm.cargoReadyDayOne = this.cargoReadyDate[0];
        this.searchForm.cargoReadyDayTwo = this.cargoReadyDate[1];
      }

      if (this.createDate.length && this.createDate.length > 1) {
        this.searchForm.createDateOne = this.createDate[0];
        this.searchForm.createDateTwo = this.createDate[1];
      }

      if (
        this.searchForm.bookingTypeArr &&
        this.searchForm.bookingTypeArr.length > 0
      ) {
        this.searchForm.airSea = this.searchForm.bookingTypeArr[0];
        this.searchForm.bookingType = this.searchForm.bookingTypeArr[1];
      }
      this.$refs.postsList.query();
    },
    zhDate_to_ehDate(e) {
      return utils.zhDate_to_enDate(e);
    },
    clearSearch() {
      this.searchForm = {};
      this.cargoReadyDate = [];
      this.createDate = [];
    },

    findPortsList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.findPortsList, {
          dictLabel: e,
        })
        .then((res) => {
          this.selectLoading = false;
          if ((res.resultCode = "success")) {
            this.PortsList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    clickOpenSearch() {
      this.openSearch = true;
      this.closeSearch = false;
    },
    clickCloseSearch() {
      this.openSearch = false;
      this.closeSearch = true;
    },
    createBooking() {
      this.creatLoading = true;
      this.$ajax
        .cPost(this.source.booking_api.bookingActivation, {
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          this.creatLoading = false;
          if (res.data.result == "true") {
            this.dialogTitle = this.$t("lang.BText34");
            this.createFlag = true;
            this.create = true;
            this.quotationMainType = "";
            this.quotationType = "";
            this.showFillIn = false;
            this.submitBooking = {};
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
        });
    },
    clickType(data) {
      this.quotationMainType = data.quotationMainType;
      this.quotationType = data.quotationType;
      this.linkQuoFlag = true;
      this.create = false;
    },
    clickShowFillIn(e) {
      if (e) {
        this.defaultTab = e;
      }
      this.showFillIn = !this.showFillIn;
    },
    clickView(data) {
      this.bookingId = data.id;
      this.toSendMsgObj = { btype: "20", bid: data.id };
      this.bookingView(this.bookingId);
      this.$set(data, "showMore", false);
    },
    clickReview(data) {
      this.review = true;
      this.edit = false;
      this.create = false;
      this.submitBooking = data;
      if(this.crmEdocId != null){
        this.submitBooking.crmEdocId = this.crmEdocId;
      }
      this.$nextTick(() => {
        this.toTop();
      });
    },
    toTop() {
      this.$refs.dwDialogMain.scrollTo(0, 0);
    },
    closeDialog() {
      this.showDelect = false;
      this.createFlag = false;
      this.reviewFlag = false;
      this.bookingId = null;
      this.create = false;
      this.review = false;
      this.edit = false;
      this.linkQuoFlag = false;
    },
    clickEdit(data) {
      this.editLoading = true;
      this.$ajax
        .cPost(this.source.booking_api.findLock, { id: data.id })
        .then((res) => {
          this.editLoading = false;
          if (res.data.result == "true") {
            this.bookingId = data.id;
            this.dialogTitle = this.$t("lang.SText141");
            this.bookingDetails(data.id);
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
        });
    },
    clickMore(data) {
      if (!data.showMore) {
        this.$set(data, "showMore", true);
      } else {
        this.$set(data, "showMore", false);
      }
    },
    reviewToEdit() {
      this.createFlag = true;
      this.edit = true;
      this.review = false;
      this.$nextTick(() => {
        this.toTop();
      });
    },
    clickDraft() {
      this.$router.push({ path: "/Booking/Draft" });
    },
    clickTemplate() {
      this.$router.push({ path: "/Booking/Template" });
    },
    cancelEdit() {
      if (this.bookingId) {
        this.closeDialog();
      } else {
        this.edit = false;
        this.review = true;
      }
    },
    clickPrevious() {
      this.createFlag = true;
      this.edit = true;
      this.review = false;
    },
    openCheckBox() {
      this.showDelect = true;
    },
    Cancel() {
      this.showDelect = false;
      this.showCancel = false;
      this.showShipping = false;
    },

    chatIconClick(row) {
      // this.$refs["iframeBox"].openCheckBox();
      // return;
      // console.log(row)
      // return;
      this.$emit("drawerClick", { num: row.bookingNo, type: "Booking" });
    },
    history(row) {
      this.$emit("historyClick", { num: row.bookingNo, type: "Booking" });
      // this.$parent.historyClick();
    },
    fillInBooking(e) {
      this.bookingId = "";
      this.edit = false;
      this.linkQuoFlag = false;
      this.fillInLoading = true;
      if (e.bookingId) {
        this.bookingDetails(e.bookingId);
      }
      if (e.dOrTId) {
        this.draftTemplatesDetails(e.dOrTId);
      }
      // if(e.draftId){
      //   this.fillInLoading = true;
      //   this.draftTemplatesDetails(e.draftId);
      // }
      if (e.bookingData) {
        setTimeout(() => {
          this.$set(this, "submitBooking", e.bookingData);
          this.showFillIn = false;
          this.createFlag = true;
          this.edit = true;
          this.dialogTitle = this.$t("lang.BText34");
          this.create = false;
          this.review = false;
          this.fillInLoading = false;
        }, 200);
      }
    },
    SaveSuccess() {
      this.review = false;
      this.createFlag = false;
      this.query();
    },
    bookingDetails(id) {
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax
        .cPost(this.source.booking_api.bookingDetails, { id: id })
        .then((res) => {
          this.$store.commit("api/SET_LOADING", false);
          if (res.data.result == "true") {
            res.data.bookingMain.containerType = JSON.parse(
              res.data.bookingMain.containerType.replace("/", "")
            );
            this.$set(this, "submitBooking", res.data.bookingMain);
            // this.submitBooking = res.data.bookingMain;
            this.$forceUpdate();

            this.showFillIn = false;
            this.createFlag = true;
            this.edit = true;
            this.dialogTitle = this.$t("lang.SText141");
            this.create = false;
            this.review = false;
            this.fillInLoading = false;
            if (this.bookingId == "") {
              this.submitBooking.id = "";
              this.submitBooking.bookingNo = "";
              this.dialogTitle = this.$t("lang.BText34");
            }
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
        });
    },
    draftTemplatesDetails(id) {
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax
        .cPost(this.source.booking_api.draftTemplatesDetails, { id: id })
        .then((res) => {
          this.$store.commit("api/SET_LOADING", false);
          if (res.data.result == "true") {
            if (res.data.bookingMain[0].containerType) {
              res.data.bookingMain[0].containerType = JSON.parse(
                res.data.bookingMain[0].containerType.replace("/", "")
              );
            }
            // res.data.bookingMain[0].cargos = JSON.parse(res.data.bookingMain[0].cargos);
            // res.data.bookingMain[0].cargos = [{commodityValue:'', commodityHscode: ''}];
            // this.NotifyParty = res.data.bookingMain[0].notifyParty
            //   ? true
            //   : false;
            // if (res.data.bookingMain[0].cargoDetails == "0") {
            //   res.data.bookingMain[0].packings.push(
            //     JSON.parse(JSON.stringify(this.CargoTem))
            //   );
            // }
            this.submitBooking = res.data.bookingMain[0];
            if (this.submitBooking.templateType == "1") {
              this.submitBooking.draftId = this.submitBooking.id;
              this.submitBooking.dataSource = "1";
            }
            this.submitBooking.id = "";
            this.showFillIn = false;
            this.createFlag = true;
            this.edit = true;
            this.dialogTitle = this.$t("lang.SText141");
            this.create = false;
            this.review = false;
            this.fillInLoading = false;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
        });
    },
    bookingView(id) {
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax
        .cPost(this.source.booking_api.bookingView, { id: id })
        .then((res) => {
          this.reviewFlag = true;
          if (res.data.result == "true") {
            this.bookingViewData = res.data.data;
            if (
              res.data.data.containerType &&
              typeof res.data.data.containerType == "string"
            ) {
              this.bookingViewData.containerType = JSON.parse(
                res.data.data.containerType
              );
            }
          }
          this.$store.commit("api/SET_LOADING", false);
        });
    },
    Manage(e) {
      this.closeDialog();
      if (e == 1) {
        this.clickDraft();
      }
      if (e == 2) {
        this.clickTemplate();
      }
      if (e == 3) {
        this.$router.push({ path: "/Quotation/quotation" });
      }
    },
    Previous() {
      this.quotationMainType = "";
      this.quotationType = "";
      this.linkQuoFlag = false;
      this.create = true;
    },
    Previous2() {
      this.linkQuoFlag = true;
      this.create = false;
    },
    Next(e) {
      this.linkQuoFlag = false;
      this.create = true;
      this.crmEdocId = e.crmEdocId;
    },
    click_shipping(data) {
      this.thisRow = data;
      this.showShipping = true;
    },
    click_CancelBooking(data) {
      this.thisRow = data;
      this.showCancel = true;
    },
    shippingAuthorize() {
      this.fillInLoading = true;
      this.$ajax
        .cPost(this.source.booking_api.userAuthorization, {
          quotationId: this.thisRow.quotationId,
          id: this.thisRow.id,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.query();
            this.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.showShipping = false;
          this.fillInLoading = false;
        });
    },
    CancelBooking() {
      this.fillInLoading = true;
      this.$ajax
        .cPost(this.source.booking_api.bookingCancel, {
          id: this.thisRow.id,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.query();
            this.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.showCancel = false;
          this.fillInLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.Booking {
  padding-bottom: 30px;
  .fillInDialog {
    padding-top: 20px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      .fillInHeader {
        height: 100px;
        display: flex;
        justify-content: space-between;
      }
      .headerTitle {
        font-size: 30px;
        color: $mainColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        margin-left: 20px;
        line-height: 100px;
      }
      .close {
        width: 30px;
        height: 30px;
        margin-top: 40px;
        margin-right: 30px;
        cursor: pointer;
        .close-icon {
          width: 30px;
          height: 30px;
          color: $mainColor;
          fill: $mainColor;
          stroke: $mainColor;
        }
      }
    }
  }

  .el-table__cell {
    vertical-align: top;
  }

  .FCL,
  .LCL {
    display: flex;
    align-items: center;
    .typeIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      fill: $mainColor;
      stroke: $mainColor;
    }
    .line {
      height: 15px;
      background: $mainColor;
      width: 1px;
      margin-right: 10px;
    }
  }

  .port {
    .svg-icon {
      fill: $background2;
      stroke: $background2;
      height: 10px !important;
      width: 10px !important;
      margin-top: -5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .tot {
    color: $background2;
    font-size: 12px;
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    z-index: 9;
    min-height: 100px;
    margin-bottom: 10px;
    .openForm {
      height: 60px;
      line-height: 60px;
      background-color: $mainColor;
      color: #fff;
      width: 160px;
      margin-top: 50px;
      .svg-icon {
        margin-right: 20px;
        margin-top: -5px;
      }
    }
    .header-left {
      display: flex;
      width: 1390px;
      justify-content: space-between;
      border-bottom: 1px solid $mainColor;
      padding-bottom: 10px;
      height: 100px;
      overflow: hidden;
      .form {
        display: flex;
        flex-wrap: wrap;

        .search-item {
          margin-right: 20px;
          margin-top: 15px;
          .form-item-title {
            text-align: left;
            color: $fontColor2;
            line-height: 40px;
          }
          .el-input__inner {
            height: 48px;
            line-height: 48px;
          }
        }
      }

      .search-button {
        display: flex;
        margin-top: 50px;
        .refresh-button {
          height: 44px;
          width: 44px;
          border: 2px solid $mainColor;
          margin-right: 10px;
          .svg-icon {
            height: 20px;
            width: 20px;
            fill: $mainColor;
            stroke: $mainColor;
            margin-top: 12px;
          }
        }
        .refresh-button:hover {
          border: 2px solid $background2;
          background-color: $background2;
        }
        .searchbutton {
          height: 48px;
          background-color: $mainColor;
          color: #fff;
          border-radius: 0;
          font-family: "montserratregular";
          font-size: 18px;
          border: 2px solid $mainColor;
        }
        .searchbutton:hover {
          border: 2px solid $background2;
          background-color: $background2;
        }
        .icon {
          height: 20px;
          width: 20px;
          color: $mainColor;
          padding: 12px;
          margin: 0 10px;
        }

        .icon:hover {
          height: 26px;
          width: 26px;
          padding: 9px;
        }
      }
    }
    .open {
      transform: all 0.2s;
      animation-name: openSearch;
      animation-duration: 0.2s;
      height: 310px;
    }

    .close {
      transform: all 0.5s;
      animation-name: closeSearch;
      animation-duration: 0.5s;
      height: 100px;
      overflow: hidden;
      cursor: pointer;
    }

    @keyframes openSearch {
      0% {
        height: 100px;
      }

      100% {
        height: 310px;
      }
    }

    @keyframes closeSearch {
      0% {
        height: 310px;
      }
      100% {
        height: 100px;
      }
    }
    .header-right {
      width: 475px;
      text-align: right;
      margin-top: 50px;
      flex-shrink: 0;
      .el-button {
        height: 60px;
        font-size: 18px;
        color: $mainColor;
        border-radius: 0;
        border: 2px solid $mainColor;
        font-family: "montserratregular";
      }
      .el-button:hover {
        background-color: $background6;
        color: #fff;
        border: 2px solid $background6;
      }
      .create {
        background-color: $background2;
        color: #fff;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
        border: 2px solid $background2;
      }
      .create:hover {
        border: 2px solid $background6;
        background-color: $background6;
        color: #fff;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .smallHeader {
    .header-left {
      width: 100%;
    }
  }

  .buttonList {
    display: flex;
    justify-content: center;
    .icon {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-top: -5px;
      .svg-icon {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
  .createDialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__close {
      display: none;
    }
    .dialog-header {
      height: 105px;
      padding: 20px;
      background-color: $mainColor;
      display: flex;
      .dw-logo {
        width: 70px;
        height: 70px;
        padding: 15px;
        .svg-icon {
          width: 70px;
          height: 70px;
          color: #fff;
        }
      }
      .close {
        width: 50px;
        height: 50px;
        margin-top: 30px;
        margin-left: 100px;
        position: absolute;
        top: 12px;
        right: 50px;
        background-color: $mainColor;
        color: #fff;
        padding: 4px;
        cursor: pointer;
        .close-icon {
          width: 50px;
          height: 50px;
          fill: #fff;
          stroke: #fff;
        }
      }

      .dialog-title {
        color: #fff;
        margin-left: 90px;
        text-align: left;
        width: 600px;
        .row1 {
          margin-top: 5px;
        }
        .row2 {
          margin-top: 14px;
          color: $fontColor1;
        }
      }
      .fill-in {
        width: 330px;
        margin-left: 60px;
        text-align: left;
        cursor: pointer;
        .fill-in-title {
          font-size: 30px;
          color: $background2;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;

          .svg-icon {
            height: 15px;
            width: 15px;
            color: #fff;
          }
        }
        .text {
          color: $fontColor1;
          line-height: 20px;
          margin-top: 6px;
          padding-bottom: 20px;
          border-bottom: 1px solid #fff;
        }
      }
      .titleDate {
        display: flex;
        border-bottom: 2px solid #fff;
        padding-top: 20px;
        text-align: left;
        height: 60px;
        .updateDate {
          .date {
            font-size: 20px;
            line-height: 30px;
            color: #fff;
            margin-right: 20px;
          }
          .date2 {
            font-size: 20px;
            line-height: 30px;
            color: $background2;
          }
        }
      }
    }
  }

  .router-slid-enter-active,
  .router-slid-leave-active {
    transition: all 0.5s;
  }
  .router-slid-enter,
  .router-slid-leave-to {
    transform: scaleY(0);
    transform-origin: 0 1%;
    opacity: 0;
  }

  .shipper {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
