<template>
  <div class="linkQuo">
    <div class="linkQuo-title">{{ $t("lang.BText117") }}</div>
    <div class="linkQuoStep1" v-show="!showQuoList">
      <div class="linkQuoSelect">
        <dw-button type="primarySmall" @click="select()">{{
          $t("lang.BText80")
        }}</dw-button>
      </div>
    </div>
    <div class="linkQuoStep2" v-if="showQuoList">
      <dwlist
        :url="source.quotation_api.quotationListData"
        :query-params="quoQueryForm"
        cellClassName="fillRow"
        ref="quoList"
        @row-click="getAQuote"
      >
        <el-table-column prop="quotationNo" label="ID" width="180px">
          <template slot-scope="scope">
            <div class="CreateDate">{{ scope.row.quotationNo }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="summry"
          :label="$t('lang.BText4')"
          :width="SearchType == 1 ? '170px' : '200px'"
        >
          <template slot-scope="scope">
            <div class="Summry">
              <div class="line1">
                {{ quotationTypeObj[scope.row.quotationMainType] }} -
                {{ quotationTypeObj[scope.row.quotationType] }}
              </div>
              <div class="line2">
                <span v-if="scope.row.fromType == 0">{{
                  scope.row.fromCyPortName
                }}</span>
                <span v-if="scope.row.fromType == 1"
                  >{{ scope.row.fromDoorCityName }},{{
                    scope.row.fromDoorCountry
                  }}</span
                >
                <span style="color: #299be4"> -> </span>
                <span v-if="scope.row.toType == 0">{{
                  scope.row.toCyPortName
                }}</span>
                <span v-if="scope.row.toType == 1"
                  >{{ scope.row.toDoorCityName }},{{
                    scope.row.toDoorCountry
                  }}</span
                >
              </div>
              <div class="line3" v-if="scope.row.containerDatas !== ''">
                <span
                  v-for="(item, index) in JSON.parse(scope.row.containerDatas)"
                  :key="index"
                  >{{ item.containerSize }}*{{ item.count }};
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="headAmounts"
          width="300px"
          :label="$t('lang.BText118')"
        >
          <template slot="header">
            <div class="head-amounts">{{ $t("lang.BText118") }}</div> </template
          ><template slot-scope="scope">
            <div
              class="Amount"
              v-for="(item, index) in scope.row.headAmounts"
              :key="index"
            >
              <div class="amountType">
                <span
                  v-if="
                    item.amountType == 0 &&
                    (index == 0 ||
                      (index != 0 &&
                        item.amountType !=
                          scope.row.headAmounts[index - 1].amountType))
                  "
                  >{{ $t("lang.BText23") }}</span
                ><span
                  v-if="
                    item.amountType == 1 &&
                    index != 0 &&
                    item.amountType !=
                      scope.row.headAmounts[index - 1].amountType
                  "
                  >{{ $t("lang.BText119") }}</span
                ><span
                  v-if="
                    item.amountType == 2 &&
                    index != 0 &&
                    item.amountType !=
                      scope.row.headAmounts[index - 1].amountType
                  "
                  >{{ $t("lang.BText26") }}</span
                >
              </div>
              <div class="headCurrency">
                {{ item.headCurrency }}
              </div>
              <div class="headAmount">
                {{ item.headAmount }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="deliveryDate"
          :label="$t('lang.BText28')"
          width="150px"
        >
          <template slot-scope="scope">
            <div class="RepliedDate" v-if="$i18n.locale == 'zh_cn'">
              {{ scope.row.repliedDate.slice(0, 10) }}
            </div>
            <div class="RepliedDate" v-if="$i18n.locale == 'en_us'">
              {{ scope.row.repliedDate.slice(0, 13) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" fixed="right">
          <template slot-scope="scope">
            <div class="RoleRight">
              <el-button
                @click="getAQuote(scope.row)"
                class="samll-list-info"
                >{{ $t("lang.BText120") }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </dwlist>
    </div>
    <div class="or">{{ $t("lang.BText76") }}</div>
    <div class="offlineFile-line1">{{ $t("lang.BText72") }}</div>
    <el-upload
      :action="baseUrl + '/crm/edoc/upload'"
      class="uploaddiv"
      :multiple="false"
      :limit="3"
      :data="uploadData"
      :show-file-list="false"
      :on-success="upLoadSuccess"
      :on-error="upLoadError"
      :on-progress="changeFile"
    >
      <div class="upload">
        <dw-button
          type="primarySmall"
          :disabled="crmEdocId != null"
          :loading="uploadloading"
          >{{ $t("lang.BText73") }}</dw-button
        >
        <div class="file-type">{{ $t("lang.BText74") }}</div>
      </div>
    </el-upload>
    <div class="offlineFile" v-if="fileName">
      <div class="fileItem">
        <div class="fileName">{{ fileName }}</div>
        <svg-icon icon-class="delete" @click="delectFile()"></svg-icon>
      </div>
    </div>
    <div class="bottom-button">
      <dw-button type="infoSmall" @click="Previous()">{{
        $t("lang.BText97")
      }}</dw-button>
      <dw-button
        type="primarySmall"
        :loading="uploadloading"
        @click="Next(crmEdocId)"
        >{{ $t("lang.QText19") }}</dw-button
      >
      <dw-button type="successSmall" @click="Next()" class="skip">
        {{ $t("lang.BText121") }} <i class="el-icon-caret-right"></i>
      </dw-button>
    </div>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import utils from "../../utils/util";
import global from "../../utils/global";
export default {
  components: {
    dwlist,
  },
  data() {
    return {
      quoQueryForm: {
        state: 20,
      },
      quotationTypeObj: {},
      showQuoList: false,
      tableDatamy: [],
      uploadData: {},
      userinfo: {},
      baseUrl: null,
      crmEdocId: null,
      fileName: null,
      uploadloading: false,
    };
  },
  created() {
    this.getBaseData();
    this.baseUrl = global.baseUrl;
  },
  mounted() {
    this.$session("userInfo").get((value) => {
      this.userinfo = value;
      this.uploadData = {
        createBy: value.email,
        businessType: "1",
      };
    });
  },
  methods: {
    changeFile(res) {
      this.uploadloading = true;
    },
    upLoadSuccess(res, file) {
      console.log(file);
      this.fileName = file.raw.name;
      this.uploadloading = false;
      if (res.result == "true") {
        this.$notify({
          title: res.message,
          type: "success",
          offset: 100,
        });
        this.crmEdocId = res.data.id;
        // this.getFileList();
      } else {
        this.$notify({
          title: res.message,
          type: "error",
          offset: 100,
        });
      }
    },
    upLoadError(res) {
      this.$notify({
        title: res.message,
        type: "success",
        offset: 100,
      });
    },
    getBaseData() {
      this.$session("userInfo").get((value) => {
        this.quoQueryForm.companyId = value.companyid;
      });
      this.$session("quotationType").get((value) => {
        value.forEach((item) => {
          this.quotationTypeObj[item.dictValue] = item.dictLabel;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
            });
          }
        });
      });
    },
    Previous() {
      this.$emit("Previous");
    },
    Next(crmEdocId) {
      this.$emit("Next", { crmEdocId: crmEdocId });
    },
    getAQuote(row, column, event) {
      var form = {
        dataSource: "2",
        airSea: row.quotationMainType,
        bookingType: row.quotationType,
        originPort: row.crmFromPort,
        originPortName: row.crmFromPortName,
        originAddress: row.fromDoorAddress,
        originCountryRegion: row.fromDoorCountry,
        originCity: row.fromDoorCity,
        originState: row.fromDoorState,
        originZipCode: row.fromDoorZipCode,
        destinationDischargePort: row.crmToPort,
        destinationDischargePortName: row.crmToPortName,
        destinationAddress: row.toDoorAddress,
        destinationCountryRegion: row.toDoorCountry,
        destinationCity: row.toDoorCity,
        destinationState: row.toDoorState,
        destinationZipCode: row.toDoorZipCode,
        originCountryRegionName: row.fromDoorCountryName,
        originCityName: row.fromDoorCityName,
        originStateName: row.fromDoorStateName,
        destinationCountryRegionName: row.toDoorCountryName,
        destinationCityName: row.toDoorCityName,
        destinationStateName: row.toDoorStateName,
        cargoReadyDay: row.cargoReadyDate,
        destinationTargetDeliveryDate: row.deliveryDate,
        totalVolume: row.totalVolume,
        totalVolumeUnit: row.totalVolumeUnit,
        totalWeight: row.totalWeight,
        totalWeightUnit: row.totalWeightUnit,
        totalQuantity: row.totalQuantity,
        totalQuantityUnit: row.totalQuantitUnit,
        incotermOne: row.incotermsType,
        incotermTwo: row.incotermsDataType,
        cargoType: row.cargoType,
        cargoDetails: "1",
        commodityHscodes: row.commodityHscodes,
        notificationRemark: row.masks,
        quotationNo: row.quotationNo,
        quotationId: row.id,
        cargos: [],
        cargoTypeName: row.cargoTypeName,
      };
      // if (this.$i18n.locale == "en_us") {
      //   form.destinationTargetDeliveryDate = utils.enDate_to_zhDate(
      //     form.destinationTargetDeliveryDate
      //   );
      //   form.cargoReadyDay = utils.enDate_to_zhDate(form.cargoReadyDay);
      // }
      form.containerType = [];
      if (row.containerDatas !== "" && row.containerDatas) {
        let bb = JSON.parse(row.containerDatas);
        bb.forEach((item, index) => {
          let cc = {
            containerTypOne: item.containerSize,
            containerTypTwo: item.count,
          };
          form.containerType.push(cc);
        });
      }
      if (row.dangerousGoodsType !== "" && row.dangerousGoodsType) {
        let aa = JSON.parse(row.dangerousGoodsType);
        form.totalBatteries = aa.indexOf("0") == -1 ? "0" : "1";
        form.hazardousMaterial = aa.indexOf("1") == -1 ? "0" : "1";
        form.totalOther = aa.indexOf("2") == -1 ? "0" : "1";
        form.lithiumBattery = aa.indexOf("3") == -1 ? "0" : "1";
      }
      if (row.services && row.services.length > 0) {
        row.services.forEach((item, index) => {
          switch (item.serviceType) {
            case "0":
              form.originPickUp = "1";
              // form.originAddress = item.address;
              // form.originPickUpAddressId = item.addressId;
              // form.originCountryRegion = item.country;
              // form.originCity = item.city;
              // form.originState = item.countryState;
              // form.originZipCode = item.zipCode;
              // form.originPickUpCountryName = item.countryName;
              // form.originPickUpCityName = item.cityName;
              // form.originPickUpStateName = item.countryStateName;
              break;
            case "1":
              form.originCustomerClearance = "1";
              break;
            case "2":
              form.originInsurance = "1";
              form.originValue = item.insurVlaue;
              form.originCurrency = item.insurCurr;
              break;
            case "3":
              form.originBondFlag = "1";
              form.originBondType = item.bondType;
              break;
            case "4":
              form.originIsfFlag = "1";
              break;
            case "5":
              form.originWarehouse = "1";
              form.originValueAddedSevice = item.warehouse;
              break;
            case "6":
              form.destinationDelivery = "1";
              // form.destinationAddress = item.address;
              // form.destinationDelivAddressId = item.addressId;
              // form.destinationCountryRegion = item.country;
              // form.destinationCity = item.city;
              // form.destinationState = item.countryState;
              // form.destinationDelivCountryName = item.countryName;
              // form.destinationDelivCityName = item.cityName;
              // form.destinationDelivStateName = item.countryStateName;
              // form.destinationZipCode = item.zipCode;
              break;
            case "7":
              form.customerClearance = "1";
              break;
            case "8":
              form.destinationBound = "1";
              item.bondType == 0
                ? (form.destinationBoundValue = "Single bond")
                : (form.destinationBoundValue = "Annual bond");
              break;
            case "9":
              form.destinationWarehouse = "1";
              form.destinationWarehouseValue = item.warehouse;
              break;
            case "10":
              form.originAmsFlag = "1";
              break;
            case "11":
              form.originAciFlag = "1";
              break;
            case "12":
              form.originDocumentation = "1";
              break;

            default:
              break;
          }
        });
      }
      this.$emit("fillInBooking", { bookingData: form, fillIn: true });
    },
    select() {
      this.quoQueryForm.companyId = this.userinfo.companyid;
      this.showQuoList = true;
    },
    delectFile() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.quotation_api.deleteFile, {
          id: this.crmEdocId,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.crmEdocId = null;
            this.fileName = null;

            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
          }
        });
    },
    getFileList() {
      let that = this;
      let datasys = {
        createBy: that.userinfo.email,
        businessType: 2,
        pageSize: 1,
        pageNo: 1,
      };
      that.$ajax
        .cPost(that.source.quotation_api.edoclist, datasys)
        .then((res) => {
          if (res.data.result == "true") {
            this.fileName =
              res.data.list[0].documentName + res.data.list[0].documentType;
          } else {
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.linkQuo {
  width: 900px;
  margin: auto;
  text-align: left;
  color: $mainColor;
  padding-bottom: 100px;
  .linkQuo-title {
    font-size: 30px;
    margin-top: 40px;
  }
  .linkQuoStep1 {
    .linkQuoSelect {
      margin: 40px 0;
    }
  }
  .linkQuoStep2 {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .or {
    font-size: 30px;
    padding: 10px;
    border-bottom: 2px solid $mainColor;
  }
  .offlineFile-line1 {
    font-size: 30px;
    margin: 30px 0;
  }
  .uploaddiv {
  }
  .el-upload {
    display: inline;
  }
  .upload {
    display: flex;
    justify-content: start;
    margin-bottom: 60px;
    .file-type {
      font-size: 14px;
      margin-left: 40px;
      line-height: 48px;
      color: #9b9b9b;
    }
  }
  .offlineFile {
    display: flex;
    justify-content: space-between;

    .fileItem {
      display: flex;
      color: $mainColor;
      line-height: 30px;
      .svg-icon {
        color: $background2;
        height: 25px;
        margin-left: 20px;
      }
    }
  }
  .Amount {
    font-size: 14px;
    // color: $background2;
    font-family: "montserratLight";
    display: flex;
    .amountType {
      width: 100px;
      color: $fontColor1;
    }
    .headCurrency {
      width: 50px;
    }
    .headAmount {
      text-align: right;
    }
  }
  .el-pagination {
    border-top: 0;
  }
  .bottom-button {
    display: flex;
    justify-content: end;
    margin-top: 80px;
    .el-button {
      margin-left: 20px;
    }
    .infoSmall {
      width: 150px;
    }
    .primarySmall {
      width: 150px;
    }
  }
  .head-amounts {
    padding-left: 120px;
  }
}
</style>