<template>
  <el-dialog
    :visible.sync="showMyDraft"
    class="MyTemDialog"
    width="70%"
    @close="closeDialog"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :append-to-body="true"
  >
    <div class="MyTemDialogHeader">
      <div class="title">
        {{ $t("lang.BText33") }}
        <div class="number">{{ listData.total }}/5</div>
      </div>
      <div class="close" @click="CancelTem()">
        <svg-icon icon-class="close"></svg-icon>
      </div>
    </div>
    <div class="MyTemDialogMain">
      <dwlist
        source="local"
        :defaultData="listData"
        :initDataFlag="false"
        :loading="false"
        :defaultPageSize="5"
        @row-click="rowClick"
        :pagination="false"
      >
        <el-table-column type="index" :label="$t('lang.BText98')" width="80">
        </el-table-column>
        <el-table-column prop="summry" :label="$t('lang.BText100')" width="250">
          <template slot-scope="scope">
            <div class="port" v-if="scope.row.createDate">
              {{ scope.row.originPortName
              }}<svg-icon icon-class="right" style="color: #299be4"></svg-icon
              >{{ scope.row.destinationDischargePortName }}
            </div>
            <div v-else class="emptySlot">Empty slot</div>
          </template>
        </el-table-column>
        <el-table-column prop="Type" :label="$t('lang.BText8')" width="100">
          <template slot-scope="scope">
            <div class="FCL" v-show="scope.row.bookingType == 'FCL'">
              <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
              <div class="line"></div>
              <svg-icon class="typeIcon" icon-class="FCL"></svg-icon>
            </div>
            <div class="LCL" v-show="scope.row.bookingType == 'LCL'">
              <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
              <div class="line"></div>
              <svg-icon class="typeIcon" icon-class="LCL"></svg-icon>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cargoInfo" :label="$t('lang.BText37')">
          <template slot-scope="scope">
            <div v-if="scope.row.containerType">
              <div
                v-for="(item, index) in JSON.parse(scope.row.containerType)"
                :key="index"
              >
                {{ item.containerTypOne }}*{{ item.containerTypTwo }}
              </div>
            </div>
            <div class="tot">
              {{ scope.row.totalVolume }} {{ scope.row.totalVolumeUnit }}
              {{ scope.row.totalWeight }} {{ scope.row.totalWeightUnit }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateDate" :label="$t('lang.BText38')">
        </el-table-column>
        <el-table-column prop="createBy" :label="$t('lang.BText39')">
        </el-table-column>
        <el-table-column fixed="right">
          <template slot-scope="scope" v-if="scope.row.createDate">
            <svg-icon
              class="delete-icon"
              @click.stop="Delete(scope.row)"
              icon-class="delete"
            ></svg-icon>
          </template>
        </el-table-column>
      </dwlist>
      <dw-check-box
        :showCheckBox="showDelect"
        @Cancel="Cancel"
        @Save="deleteDraft"
        :CancelButton="$t('lang.QText79')"
        :primaryButton="$t('lang.BText40')"
      >
        {{ $t("lang.BText99") }}
      </dw-check-box>
      <dw-check-box
        :showCheckBox="showSave"
        @Cancel="Cancel"
        @Save="Save"
        :CancelButton="$t('lang.QText79')"
        :primaryButton="$t('lang.SText13')"
      >
        <div class="line2" v-if="slot > listData.total">
          {{ $t("lang.BText101") }} {{ slot }}?
        </div>
        <div class="line2" v-else>
          {{ $t("lang.BText101") }} {{ slot }} <br />{{ $t("lang.BText102") }}
        </div>
      </dw-check-box>
    </div>
  </el-dialog>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
import test from "../../test/test";
export default {
  components: {
    dwlist,
    dwCheckBox,
  },
  props: {
    CancelButton: {
      type: String,
      default: "Cancel",
    },
    primaryButton: {
      type: String,
      default: "Save",
    },
    showMyDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDelect: false,
      slot: null,
      showSave: false,
      draftQueryForm: {},
      listData: [],
      deleteId: "",
    };
  },
  watch: {
    showMyDraft: function (val) {
      if (val) {
        this.getDraftList();
      }
    },
  },
  created() {
    this.getDraftList();
  },
  methods: {
    CancelTem() {
      this.$emit("Cancel");
    },
    Save() {
      this.$emit("Save", { slot: this.slot, id: this.id });
      this.showSave = false;
    },
    closeDialog() {},
    Delete(data) {
      this.showDelect = true;
      this.deleteId = data.id;
    },
    deleteDraft() {
      let url = this.source.booking_api.deleteTemplate;
      this.loading = true;
      this.$ajax
        .cPost(url, { id: this.deleteId })
        .then((res) => {
          this.loading = false;
          this.showDelect = false;
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.getDraftList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Cancel() {
      this.showDelect = false;
      this.showSave = false;
    },
    rowClick(row) {
      this.slot = row.serialNumber;
      this.id = row.id;
      this.showSave = true;
    },
    getDraftList() {
      let url = this.source.booking_api.draftList;
      this.loading = true;
      this.$ajax
        .cPost(url)
        .then((res) => {
          this.loading = false;
          if (res.data.result) {
            this.listData = [...[], ...res.data.list];
            var total = res.data.list.length;
            for (let index = 1; index <= 5; index++) {
              if (index <= total) {
                this.listData[index-1].serialNumber = index;
              } else {
                this.listData.push({ serialNumber: this.listData.length + 1 });
              }
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang='scss'>
@import "../../assets/base.scss";
.MyTemDialog {
  font-family: "montserratregular";
  top: 10% !important;
  .el-dialog__header {
    display: none;
  }
  .MyTemDialogHeader {
    display: flex;
    justify-content: space-between;
    .title {
      display: flex;
      color: $background2;
      font-size: 30px;
      margin-left: 20px;
      line-height: 30px;

      .number {
        font-size: 20px;
        line-height: 40px;
        margin-left: 30px;
      }
    }
  }
  .MyTemDialogMain {
    font-size: 30px;
    padding: 20px 10px;
    color: $mainColor;
    line-height: 40px;
    text-align: left;
  }

  .svg-icon {
    height: 30px;
    width: 30px;
    color: $mainColor;
  }

  .emptySlot {
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    color: $borderColor1 !important;
  }

  .delete-icon {
    height: 20px;
    width: 20px;
    color: $background2;
  }
  .FCL,
  .LCL {
    display: flex;
    align-items: center;
    .typeIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      fill: $mainColor;
      stroke: $mainColor;
    }
    .line {
      height: 15px;
      background: $mainColor;
      width: 1px;
      margin-right: 10px;
    }
  }
  .port {
    .svg-icon {
      color: $background2;
      height: 10px !important;
      width: 10px !important;
      margin-top: -5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
</style>