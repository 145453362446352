<template>
  <div
    class="booking-create-step"
    id="bookingCreateStep"
    ref="bookingCreateStep"
  >
    <div class="stepDiv step1">
      <div class="timeline">
        <dw-time-line
          styleType="2"
          :data="stepList"
          :status="step"
        ></dw-time-line>
      </div>
      <div class="booking-create-form">
        <el-form
          :model="form0"
          :rules="rules"
          ref="bookingForm0"
          v-show="step == 0"
        >
          <div class="dw-card2">
            <div class="title">{{ $t("lang.BText77") }}</div>
            <div class="main">
              <el-form-item class="form-item" prop="referenceNo">
                <div class="form-item-title">{{ $t("lang.BText78") }}</div>
                <el-input style="width: 50%" v-model="form0.referenceNo">
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">
              {{ $t("lang.BText17") }} / {{ $t("lang.BText3") }} /
              {{ $t("lang.BText22") }}
            </div>
            <div class="main">
              <!-- <el-form-item class="form-item radio" prop="chooseRole">
                <div class="radioTitle">My role in this shipment：</div>
                <el-radio v-model="form0.chooseRole" label="0">
                  Shipper
                </el-radio>
                <el-radio v-model="form0.chooseRole" label="1">
                  Consignee
                </el-radio>
              </el-form-item> -->
              <el-form-item
                class="form-item"
                prop="shipper"
                :label="$t('lang.BText17')"
              >
                <div class="input-title" v-if="false">
                  Tianjin Woshang International Trade Company
                  <div class="user">Partner</div>
                </div>
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover">
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li v-for="(item,index) in recentlyDataShipper" :key="index" @click="chooseRecentlyShipper(item)">
                        <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{
                            item.country
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="form0.shipper"
                    resize="none"
                    maxlength="200"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover">
                  <ul class="RecentlyUl">
                    <li v-for="(item,index) in recentlyDataShipper" :key="index" @click="chooseRecentlyShipper(item)">
                      <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{
                            item.country
                          }}</span>
                        </div>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <el-form-item
                class="form-item"
                prop="consignee"
                :label="$t('lang.BText3')"
              >
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover">
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li v-for="(item,index) in recentlyDataconsignee" :key="index" @click="chooseRecentlyconsignee(item)">
                        <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{
                            item.country
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="form0.consignee"
                    resize="none"
                    maxlength="200"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover">
                  <ul class="RecentlyUl">
                    <li v-for="(item,index) in recentlyDataconsignee" :key="index" @click="chooseRecentlyconsignee(item)">
                      <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{
                            item.country
                          }}</span>
                        </div>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <!-- <el-checkbox
                class="quotation-detials-checkbox"
                label="Notify party is same as consignee"
                v-model="NotifyParty"
                true-label="1"
                false-label="0"
                @change="checkNotifyParty"
              ></el-checkbox> -->
              <el-form-item
                class="form-item"
                prop="notifyParty"
                :label="$t('lang.BText22')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText97'),
                    trigger: 'blur',
                  },
                ]"
              >
                <!-- <div class="input-title">BEST VT INC.</div> -->
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover">
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li v-for="(item,index) in recentlyDatanotifyParty" :key="index" @click="chooseRecentlynotifyParty(item)">
                        <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{
                            item.country
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="form0.notifyParty"
                  resize="none"
                  maxlength="200"
                    slot="reference"
                >
                </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover">
                  <ul class="RecentlyUl">
                    <li v-for="(item,index) in recentlyDatanotifyParty" :key="index" @click="chooseRecentlynotifyParty(item)">
                      <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{
                            item.country
                          }}</span>
                        </div>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
            </div>
          </div>

          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText38") }}</div>
            <div class="main">
              <el-form-item
                class="form-item incotermOne incotermsType"
                prop="incotermOne"
                :label="$t('lang.QText39')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText98'),
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <div class="radiobox">
                  <div
                    class="option"
                    :class="{ optionFocus: form0.incotermOne == 0 }"
                    @click="clickType(0)"
                  >
                    {{ $t("lang.QText40") }}
                  </div>
                  <div
                    class="option"
                    :class="{ optionFocus: form0.incotermOne == 1 }"
                    @click="clickType(1)"
                  >
                    {{ $t("lang.QText41") }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                class="form-item radio Incoterms-radio incotermsType"
                prop="incotermTwo"
                v-if="form0.incotermOne == 0 || form0.incotermOne == 1"
                label=" "
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText98'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio-group v-model="form0.incotermTwo">
                  <template v-if="form0.incotermOne == 0">
                    <el-radio
                      :label="item.dictValue"
                      v-for="item in incotermsData.buyData"
                      :key="item.dictValue"
                      >{{ item.dictLabel }}</el-radio
                    ></template
                  >
                  <template v-if="form0.incotermOne == 1">
                    <el-radio
                      :label="item.dictValue"
                      v-for="item in incotermsData.sellerData"
                      :key="item.dictValue"
                      >{{ item.dictLabel }}</el-radio
                    ></template
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>

        <el-form
          :model="form1"
          :rules="rules"
          ref="bookingForm1"
          v-show="step == 1"
        >
          <div class="dw-card2">
            <div class="title">{{ $t("lang.BText23") }}</div>
            <div class="main">
              <el-form-item
                class="form-item"
                prop="originPort"
                :label="$t('lang.BText24')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText100'),
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-select
                  class="step1-select"
                  v-model="form1.originPort"
                  :placeholder="$t('lang.BText80')"
                  filterable
                  remote
                  clearable
                  :remote-method="findPortsList"
                  :loading="selectLoading"
                  ref="originPort"
                >
                  <el-option
                    v-for="item in PortsList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="cargoReadyDay"
                  :label="$t('lang.QText21')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText101'),
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="form1.cargoReadyDay"
                    type="date"
                    :placeholder="$t('lang.QText23')"
                    :picker-options="pickerOptions"
                    @change="CargoReadyDayChange"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>

              <div class="form-item-title" style="margin-top: 30px">
                {{ $t("lang.BText124") }}
              </div>
              <div class="OriginServices-checkbox">
                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.QText49')"
                  v-model="form1.originPickUp"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <div v-if="form1.originPickUp == 1">
                  <el-form-item
                    class="form-item"
                    prop="originAddress"
                    :label="$t('lang.QText50')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.NText102'),
                      },
                    ]"
                  >
                    <el-popover
                      placement="bottom"
                      popper-class="Recentlypopover"
                      trigger="hover">
                      <div class="Recentlyused">
                        <p>最近常用</p>
                        <ul>
                          <li v-for="(item,index) in recentlyData" :key="index" @click="chooseRecently(item)">
                            <span v-show="item.address">{{ item.address }}, </span>
                            <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                            <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                            <span v-show="item.countryShow">{{
                              item.countryShow
                            }}</span>
                          </li>
                        </ul>
                      </div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        :placeholder="$t('lang.BText41')"
                        v-model="form1.originAddress"
                        resize="none"
                        maxlength="100"
                        slot="reference"
                      >
                      </el-input>
                    </el-popover>
                    <el-popover
                      placement="left"
                      popper-class="RecentlypopoverRight"
                      trigger="hover">
                      <ul class="RecentlyUl">
                        <li v-for="(item,index) in recentlyData" :key="index" @click="chooseRecently(item)">
                          <span v-show="item.address">{{ item.address }}, </span>
                          <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                          <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                          <span v-show="item.countryShow">{{
                            item.countryShow
                          }}</span>
                        </li>
                      </ul>
                      <i class="el-icon-more more" slot="reference"></i>
                    </el-popover>
                  </el-form-item>
                  <div class="form-line">
                    <el-form-item
                      class="form-item"
                      prop="originCountryRegion"
                      :label="$t('lang.QText52')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.SText161'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.originCountryRegion"
                        :placeholder="$t('lang.BText80')"
                        @change="countryChange($event, 0)"
                        filterable
                        ref="originCountryRegion"
                      >
                        <el-option
                          v-for="item in countryList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      class="form-item"
                      prop="originState"
                      :label="$t('lang.QText53')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.SText160'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.originState"
                        :placeholder="$t('lang.BText80')"
                        @change="stateChange($event, 0)"
                        filterable
                        :loading="originPickUpStateLoading"
                        ref="originState"
                      >
                        <el-option
                          v-for="item in originPickUpStateList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="form-line">
                    <el-form-item
                      class="form-item"
                      prop="originCity"
                      :label="$t('lang.QText54')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.SText159'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.originCity"
                        :placeholder="$t('lang.BText80')"
                        filterable
                        :loading="originPickUpCityLoading"
                        ref="originCity"
                      >
                        <el-option
                          v-for="item in originPickUpCityList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      class="form-item unspin"
                      prop="originZipCode"
                      :label="$t('lang.QText55')"
                    >
                      <el-input
                        v-model="form1.originZipCode"
                        maxlength="100"
                        type="number"
                        @keydown.native="limite"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>

                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.BText25')"
                  v-model="form1.originCustomerClearance"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.QText57')"
                  v-model="form1.originInsurance"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <div
                  class="insurance-line checkbox-content"
                  v-if="form1.originInsurance == 1"
                >
                  <el-form-item
                    class="form-item unspin"
                    prop="originValue"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.NText103'),
                      },
                    ]"
                    :label="$t('lang.QText58')"
                  >
                    <el-input
                      class="insurance-input"
                      v-model="form1.originValue"
                      maxlength="100"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    class="form-item"
                    prop="originCurrency"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.NText104'),
                        trigger: 'blur',
                      },
                    ]"
                    :label="$t('lang.QText59')"
                  >
                    <el-select
                      class="insurance-select"
                      v-model="form1.originCurrency"
                      :placeholder="$t('lang.BText80')"
                    >
                      <el-option
                        v-for="item in CurrentyList"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- <el-checkbox
                  class="quotation-detials-checkbox"
                  label="Bond"
                  v-model="form1.originBond"
                ></el-checkbox>
                <div class="checkbox-content" v-if="form1.originBond == 1">
                  <div class="bond-radio">
                    <el-radio
                      v-model="form.originBondValue"
                      label="Single bond"
                    >
                      Single bond
                    </el-radio>
                    <el-radio
                      v-model="form.originBondValue"
                      label="Annual bond"
                    >
                      Annual bond
                    </el-radio>
                  </div>
                </div> -->
                <el-checkbox
                  class="quotation-detials-checkbox"
                  label="ISF"
                  v-model="form1.originIsfFlag"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.QText61')"
                  v-model="form1.originDocumentation"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  label="AMS"
                  v-model="form1.originAmsFlag"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  label="ACI"
                  v-model="form1.originAciFlag"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.QText63')"
                  v-model="form1.originWarehouse"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <div class="checkbox-content" v-if="form1.originWarehouse == 1">
                  <div class="form-line">
                    <el-form-item
                      class="form-item"
                      prop="originValueAddedSevice"
                      :label="$t('lang.QText64')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.NText105'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.originValueAddedSevice"
                        :placeholder="$t('lang.BText80')"
                      >
                        <el-option
                          v-for="item in WarehouseList.originWarehouse"
                          :key="item.dictValue"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.BText26") }}</div>
            <div class="main">
              <el-form-item
                class="form-item"
                prop="destinationDischargePort"
                :label="$t('lang.BText27')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText106'),
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-select
                  class="step1-select"
                  v-model="form1.destinationDischargePort"
                  :placeholder="$t('lang.BText80')"
                  filterable
                  remote
                  clearable
                  :remote-method="findPortsList"
                  :loading="selectLoading"
                  ref="destinationDischargePort"
                >
                  <el-option
                    v-for="item in PortsList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="destinationTargetDeliveryDate"
                  :label="$t('lang.BText28')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText107'),
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="form1.destinationTargetDeliveryDate"
                    type="date"
                    :placeholder="$t('lang.BText81')"
                    :picker-options="deliveryDayOptions"
                    @change="deliveryDayChange"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="form-item-title" style="margin-top: 30px">
                {{ $t("lang.BText125") }}
              </div>
              <div class="OriginServices-checkbox">
                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.QText67')"
                  v-model="form1.destinationDelivery"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <div
                  v-if="form1.destinationDelivery == 1"
                >
                  <el-form-item
                    class="form-item"
                    prop="destinationAddress"
                    :label="$t('lang.QText50')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.NText108'),
                      },
                    ]"
                  >
                  
                    <el-popover
                      placement="bottom"
                      popper-class="Recentlypopover"
                      trigger="hover">
                      <div class="Recentlyused">
                        <p>最近常用</p>
                        <ul>
                          <li v-for="(item,index) in recentlyDataDe" :key="index" @click="chooseRecentlyDe(item)">
                            <span v-show="item.address">{{ item.address }}, </span>
                            <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                            <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                            <span v-show="item.countryShow">{{
                              item.countryShow
                            }}</span>
                          </li>
                        </ul>
                      </div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        :placeholder="$t('lang.BText41')"
                        v-model="form1.destinationAddress"
                        resize="none"
                        maxlength="100"
                        slot="reference"
                      >
                      </el-input>
                    </el-popover>
                    <el-popover
                      placement="left"
                      popper-class="RecentlypopoverRight"
                      trigger="hover">
                      <ul class="RecentlyUl">
                        <li v-for="(item,index) in recentlyDataDe" :key="index" @click="chooseRecentlyDe(item)">
                          <span v-show="item.address">{{ item.address }}, </span>
                          <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                          <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                          <span v-show="item.countryShow">{{
                            item.countryShow
                          }}</span>
                        </li>
                      </ul>
                      <i class="el-icon-more more" slot="reference"></i>
                    </el-popover>
                  </el-form-item>
                  <div class="form-line">
                    <el-form-item
                      class="form-item"
                      prop="destinationCountryRegion"
                      :label="$t('lang.QText52')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.SText161'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.destinationCountryRegion"
                        :placeholder="$t('lang.BText80')"
                        @change="countryChange($event, 1)"
                        filterable
                        ref="destinationCountryRegion"
                      >
                        <el-option
                          v-for="item in countryList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      class="form-item"
                      prop="destinationState"
                      :label="$t('lang.QText53')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.SText160'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.destinationState"
                        :placeholder="$t('lang.BText80')"
                        @change="stateChange($event, 1)"
                        filterable
                        :loading="destinationDelivStateLoading"
                        ref="destinationState"
                      >
                        <el-option
                          v-for="item in destinationDelivStateList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="form-line">
                    <el-form-item
                      class="form-item"
                      prop="destinationCity"
                      :label="$t('lang.QText54')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.SText159'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.destinationCity"
                        :placeholder="$t('lang.BText80')"
                        filterable
                        :loading="destinationDelivCityLoading"
                        ref="destinationCity"
                      >
                        <el-option
                          v-for="item in destinationDelivCityList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      class="form-item unspin"
                      prop="originZipCode"
                      :label="$t('lang.QText55')"
                    >
                      <el-input
                        v-model="form1.destinationZipCode"
                        maxlength="100"
                        type="number"
                        @keydown.native="limite"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.BText25')"
                  v-model="form1.customerClearance"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  label="Bond"
                  v-model="form1.destinationBound"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <div
                  class="checkbox-content"
                  v-if="form1.destinationBound == 1"
                >
                  <el-form-item
                    class="form-item bond-radio incotermsType"
                    prop="destinationBoundValue"
                    label=" "
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.NText110'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-radio-group v-model="form1.destinationBoundValue">
                      <el-radio label="Single bond">
                        {{ $t("lang.QText70") }}
                      </el-radio>
                      <el-radio label="Annual bond">
                        {{ $t("lang.QText71") }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <el-checkbox
                  class="quotation-detials-checkbox"
                  :label="$t('lang.QText63')"
                  v-model="form1.destinationWarehouse"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
                <div
                  class="checkbox-content"
                  v-if="form1.destinationWarehouse == 1"
                >
                  <div class="form-line">
                    <el-form-item
                      class="form-item"
                      prop="destinationWarehouseValue"
                      :label="$t('lang.QText64')"
                      :rules="[
                        {
                          required: true,
                          message: $t('lang.NText105'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="date-card-select"
                        v-model="form1.destinationWarehouseValue"
                        :placeholder="$t('lang.BText80')"
                      >
                        <el-option
                          v-for="item in WarehouseList.destWarehouse"
                          :key="item.dictValue"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>

        <el-form
          :model="form2"
          :rules="rules"
          ref="bookingForm2"
          v-show="step == 2"
        >
          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText26") }}</div>
            <div class="main">
              <div class="form-title" style="margin-bottom: 10px">
                * {{ $t("lang.BText82") }}
              </div>
              <div
                v-for="(domain, index) in form2.weightList"
                :key="'ct' + index"
              >
                <el-form-item
                  :prop="`weightList.${index}.containerTypOne`"
                  class="weightItem"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText96'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="weight-select"
                    v-model="domain.containerTypOne"
                    placeholder=""
                    @change="weightSelectChange"
                  >
                    <el-option
                      v-for="item in ContainerTypeList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    class="weightInput"
                    v-model="domain.containerTypTwo"
                    max="99"
                    min="0"
                    :maxlength="2"
                    @input="
                      domain.containerTypTwo = domain.containerTypTwo.replace(
                        /[^\d]/g,
                        ''
                      )
                    "
                  >
                    <div slot="prefix" class="chenghao">X</div>
                  </el-input>
                  <div class="add" @click="Add(index)">
                    <svg-icon icon-class="up"></svg-icon>
                  </div>
                  <div class="prep" @click="Prep(index)">
                    <svg-icon icon-class="down"></svg-icon>
                  </div>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    @click.prevent="removeDomain(domain)"
                    v-if="form2.weightList.length > 1"
                  ></el-button>
                  <el-button
                    v-if="
                      index == form2.weightList.length - 1 &&
                      index < ContainerTypeList.length - 1
                    "
                    type="text"
                    @click.prevent="addDomain"
                    >{{ $t("lang.BText83") }}</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText27") }}</div>
            <div class="main">
              <el-form-item
                class="form-item"
                prop="cargoType"
                :label="$t('lang.QText43')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.SText140'),
                  },
                ]"
              >
                <el-select
                  class="date-card-select"
                  v-model="form2.cargoType"
                  :placeholder="$t('lang.BText80')"
                  ref="cargoType"
                  style="width: 50%"
                >
                  <el-option
                    v-for="item in CargoTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="form-item-title required" style="margin-bottom: 10px">
                * {{ $t("lang.QText47") }}
              </div>
              <div
                v-for="(domain, index) in form2.dbcgList"
                :key="index"
                style="display: flex"
              >
                <el-form-item
                  :prop="`dbcgList.${index}.commodityValue`"
                  :rules="{
                    required: true,
                    message: $t('lang.NText95'),
                    trigger: 'blur',
                  }"
                  class="weightItem form-item unspin"
                >
                  <el-select
                    class="weight-select"
                    v-model="domain.commodityValue"
                    placeholder=""
                    ref="commodityValue"
                    filterable
                  >
                    <el-option
                      v-for="item in CommodityType"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-input
                  class="weightInput"
                  type="number"
                  @keydown.native="limite"
                  v-model="domain.commodityHscode"
                  :placeholder="$t('lang.BText84')"
                  style="width: 130px"
                ></el-input>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click.prevent="removeDbcg(domain)"
                  v-if="form2.dbcgList.length > 1"
                  style="height: 48px; padding: 0; margin-left: 10px"
                ></el-button>
                <el-button
                  type="text"
                  v-if="index == form2.dbcgList.length - 1"
                  @click.prevent="addDbcg"
                  style="height: 48px; padding: 0; margin-left: 10px"
                  >{{ $t("lang.BText42") }}</el-button
                >
              </div>
              <el-form-item
                class="form-item radio"
                prop="cargoDetails"
                style="border-bottom: 2px solid #9b9b9b"
              >
                <div class="radioTitle">{{ $t("lang.QText42") }}：</div>
                <el-radio v-model="form2.cargoDetails" label="1">
                  {{ $t("lang.BText43") }}
                </el-radio>
                <el-radio v-model="form2.cargoDetails" label="0">
                  {{ $t("lang.BText44") }}
                </el-radio>
              </el-form-item>
              <div class="packingList" v-if="form2.cargoDetails == 0">
                <div
                  class="group"
                  v-for="(group, index) in form2.packings"
                  :key="index"
                >
                  <div class="group-title">Group {{ index + 1 }}</div>
                  <div class="group-main unspin">
                    <div class="group-line">
                      <div class="group-item">
                        <div class="group-item-title">
                          {{ $t("lang.BText45") }}
                        </div>
                        <el-input
                          style="width: 90%; margin-right: 20px"
                          v-model="group.packageName"
                        >
                        </el-input>
                      </div>
                      <div class="group-item">
                        <div class="group-item-title required">
                          {{ $t("lang.BText46") }}. *
                        </div>
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.packageValue'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="width100"
                              v-model="group.packageValue"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="select120"
                            v-model="group.commodityType"
                            @change="
                              ($event) => {
                                commodityTypeChange($event, index);
                              }
                            "
                            placeholder=""
                          >
                            <el-option
                              v-for="item in QtyType"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div
                        class="group-item"
                        style="width: 170px"
                        v-if="group.commodityType == 'Pallet'"
                      >
                        <div class="group-item-title required">
                          {{ $t("lang.BText85") }} *
                        </div>
                        <el-form-item
                          label=""
                          :prop="'packings.' + index + '.packingFour'"
                          :rules="{
                            required: true,
                            message: $t('lang.text8'),
                            trigger: 'blur',
                          }"
                        >
                          <el-input
                            style="width: 100px"
                            v-model="group.packingFour"
                            @input="computedValue(index)"
                            type="number"
                            @keydown.native="limite"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="group-line">
                      <el-radio
                        v-model="group.packageType"
                        @change="
                          ($event) => {
                            packageTypeRadio($event, index);
                          }
                        "
                        label="0"
                      >
                        {{ $t("lang.BText86") }}
                      </el-radio>
                      <el-radio
                        v-model="group.packageType"
                        @change="
                          ($event) => {
                            packageTypeRadio($event, index);
                          }
                        "
                        label="1"
                      >
                        {{ $t("lang.BText87") }}
                      </el-radio>
                    </div>
                    <template v-if="group.packageType == 0">
                      <div class="group-line">
                        <div class="Lenght-title Lenght-title1">
                          {{ $t("lang.BText88") }}
                        </div>
                        <div class="Lenght-title Lenght-title2">
                          {{ $t("lang.BText89") }}
                        </div>
                        <div class="Lenght-title Lenght-title3">
                          {{ $t("lang.BText90") }}
                        </div>
                        <div class="QtyType1">/ {{ group.commodityType }}</div>
                        <div class="Lenght-title Lenght-title4">
                          {{ $t("lang.BText48") }}
                        </div>
                        <div class="QtyType2">/ {{ group.commodityType }}</div>
                      </div>
                      <div class="group-line">
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsL'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsL"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsW'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsW"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsH'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsH"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="select120"
                            v-model="group.dimensionsOne"
                            placeholder=""
                            disabled
                          >
                            <el-option
                              v-for="item in packUnit"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsWeight'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsWeight"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="select120"
                            v-model="group.dimensionsWeightType"
                            placeholder=""
                          >
                            <el-option
                              v-for="item in WeightUnitList"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </template>
                    <template v-if="group.packageType == 1">
                      <div class="group-line">
                        <div class="Lenght-title Lenght-title5">
                          {{ $t("lang.BText47") }}
                          <div class="QtyType">({{ group.commodityType }})</div>
                        </div>
                        <div class="Lenght-title">
                          {{ $t("lang.BText48") }}
                          <div class="QtyType">({{ group.commodityType }})</div>
                        </div>
                      </div>
                      <div class="group-line">
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.packageVolumeOne'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              style="width: 100px"
                              v-model="group.packageVolumeOne"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="weight-select"
                            v-model="group.packageVolumeTwo"
                            placeholder=""
                            style="width: 150px"
                            disabled
                          >
                            <el-option
                              v-for="item in VolumeUnitList"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.packageVolumeThere'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              style="width: 100px"
                              v-model="group.packageVolumeThere"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="weight-select"
                            v-model="group.packageVolumeFour"
                            placeholder=""
                            style="width: 150px"
                          >
                            <el-option
                              v-for="item in WeightUnitList"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div
                    class="delect"
                    v-if="form2.packings.length > 1"
                    @click="delectGroup(index)"
                  >
                    <svg-icon icon-class="delete"></svg-icon>
                  </div>
                </div>
                <div class="addButton" @click="addNewGroup()">
                  {{ $t("lang.BText49") }}
                </div>
              </div>
              <div class="total">
                <el-form-item
                  class="form-item unspin"
                  prop="totalWeight"
                  :label="$t('lang.QText28')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText136'),
                    },
                    {
                      pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
                      message: $t('lang.QText96'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    class="input48"
                    v-model="form2.totalWeight"
                    type="number"
                    @keydown.native="limite2"
                    maxlength="10"
                    ><template slot="append"
                      ><el-select
                        class="Unit-select"
                        v-model="form2.totalWeightUnit"
                        placeholder=""
                      >
                        <el-option
                          v-for="item in WeightUnitList"
                          :key="item.value"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                          :disabled="item.disabled"
                        >
                        </el-option> </el-select
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  class="form-item unspin"
                  prop="totalVolume"
                  :label="$t('lang.QText29')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText135'),
                    },
                    {
                      pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
                      message: $t('lang.QText96'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    class="input48"
                    v-model="form2.totalVolume"
                    type="number"
                    @keydown.native="limite2"
                    maxlength="10"
                  >
                    <template slot="append"
                      ><el-select
                        class="Unit-select"
                        v-model="form2.totalVolumeUnit"
                        placeholder=""
                      >
                        <el-option
                          v-for="item in VolumeUnitListForTotal"
                          :key="item.value"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                          :disabled="item.disabled"
                        >
                        </el-option> </el-select></template
                  ></el-input>
                </el-form-item>

                <el-form-item
                  class="form-item unspin"
                  prop="totalQuantity"
                  :label="$t('lang.QText30')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText137'),
                      trigger: 'blur',
                    },
                    {
                      min: 1,
                      max: 10,
                      message: $t('lang.QText93'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    class="input48"
                    v-model="form2.totalQuantity"
                    type="number"
                    @keydown.native="limite"
                    maxlength="10"
                  >
                    <template slot="append"
                      ><el-select
                        class="Unit-select"
                        v-model="form2.totalQuantityUnit"
                        placeholder=""
                        disabled
                      >
                        <el-option
                          v-for="item in CargoUnitListForTotal"
                          :key="item.value"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                          :disabled="item.disabled"
                        >
                        </el-option> </el-select></template
                  ></el-input>
                </el-form-item>
                <div
                  class="form-item-title"
                  style="margin-bottom: 10px; color: #808080"
                >
                  {{ $t("lang.BText51") }}
                </div>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  :placeholder="$t('lang.BText41')"
                  v-model="form2.totalAddress"
                >
                </el-input>
                <div
                  class="form-item-title"
                  style="padding-top: 30px; margin-bottom: 10px"
                >
                  {{ $t("lang.BText52") }}
                </div>
                <div class="OriginServices-checkbox">
                  <el-checkbox
                    class="quotation-detials-checkbox"
                    v-model="form2.totalBatteries"
                    true-label="1"
                    false-label="0"
                  >
                    <svg-icon icon-class="batteries"></svg-icon>
                    {{ $t("lang.QText32") }}</el-checkbox
                  >
                  <el-checkbox
                    class="quotation-detials-checkbox HazardousMaterials"
                    v-model="form2.hazardousMaterial"
                    true-label="1"
                    false-label="0"
                  >
                    <svg-icon icon-class="HazardousMaterials"></svg-icon
                    >{{ $t("lang.QText33") }}</el-checkbox
                  >
                  <el-checkbox
                    class="quotation-detials-checkbox"
                    v-model="form2.totalOther"
                    true-label="1"
                    false-label="0"
                  >
                    <svg-icon icon-class="other"></svg-icon
                    >{{ $t("lang.QText34") }}</el-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </el-form>

        <el-form
          :model="form3"
          :rules="rules"
          ref="bookingForm3"
          v-show="step == 3"
        >
          <div class="main">
            <div class="dw-card2">
              <div class="title">{{ $t("lang.BText31") }}</div>
              <div class="main">
                <el-form-item class="form-item">
                  <div class="group-line">
                    <el-radio
                      v-model="form3.notification"
                      label="Just my account"
                    >
                      {{ $t("lang.BText53") }}
                    </el-radio>
                    <el-radio
                      v-model="form3.notification"
                      label="Multi-party cooperation"
                    >
                      {{ $t("lang.BText54") }}
                    </el-radio>
                  </div>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="pricingBookingComfirmation"
                  v-show="form3.notification == 'Just my account'"
                  :label="$t('lang.BText92')"
                >
                  <el-input
                    class="input48"
                    v-model="form3.pricingBookingComfirmation"
                  >
                  </el-input>
                </el-form-item>
                <template
                  v-if="form3.notification == 'Multi-party cooperation'"
                >
                  <div class="form-item-title1">{{ $t("lang.BText91") }}</div>
                  <div class="checkbox-content">
                    <div class="form-item-title">
                      {{ $t("lang.BText92") }}
                      <span style="font-size: 12px"
                        >{{ $t("lang.BText93") }}
                      </span>
                    </div>
                    <el-form-item
                      class="form-item"
                      prop="bookingComfirmation"
                      v-show="form3.notification == 'Multi-party cooperation'"
                    >
                      <el-input
                        class="input48"
                        v-model="form3.bookingComfirmation"
                        style="width: 100%"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="form-item-title1">{{ $t("lang.BText94") }}</div>
                  <div class="checkbox-content">
                    <el-form-item
                      class="form-item"
                      prop="pricingOriginCharge"
                      :label="$t('lang.BText92')"
                    >
                      <el-input
                        class="input48"
                        style="width: 60%"
                        v-model="form3.pricingOriginCharge"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="form-item-title1">
                    {{ $t("lang.BText95") }}
                  </div>
                  <div class="checkbox-content">
                    <el-form-item
                      class="form-item"
                      prop="freightDestinationCharge"
                      label="E-mail"
                    >
                      <el-input
                        style="width: 60%"
                        class="input48"
                        v-model="form3.freightDestinationCharge"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-form>

        <div class="bottom-button">
          <dw-button
            type="successSmall"
            v-show="step < 4"
            @click="clickSaveDraft()"
            >{{ $t("lang.BText96") }}</dw-button
          >
          <dw-button type="infoSmall" @click="Previous()">{{
            $t("lang.BText97")
          }}</dw-button>
          <dw-button type="primarySmall" v-show="step < 3" @click="Next()">{{
            $t("lang.QText19")
          }}</dw-button>
          <dw-button type="primarySmall" v-show="step == 3" @click="Review()">{{
            $t("lang.QText76")
          }}</dw-button>
        </div>
      </div>
    </div>
    <draft-dialog
      :showMyDraft="showMyDraft"
      @Cancel="Cancel"
      @Save="SaveDraft"
      v-loading="saveDraftLoading"
    ></draft-dialog>
  </div>
</template>

<script>
import dwTimeLine from "../../components/dwTimeline/dwTimeLine.vue";
import DraftDialog from "./draftDialog.vue";
import utils from "../../utils/util.js";
export default {
  components: {
    dwTimeLine,
    DraftDialog,
  },
  props: {},
  data() {
    return {
      userInfo: {},
      RecentlyusedShow:false,
      recentlyData:[],
      recentlyDataShipper:[],
      recentlyDatanotifyParty:[],
      recentlyDataconsignee:[],
      recentlyDataDe:[],
      CargoTem: {
        id: "",
        packageName: "",
        packageValue: "",
        commodityType: "Pallet",
        packingFour: "",
        packageType: "0",
        dimensionsL: "",
        dimensionsW: "",
        dimensionsH: "",
        dimensionsOne: "m",
        dimensionsWeight: "",
        dimensionsWeightType: "kg",
        packageVolumeOne: "",
        packageVolumeThere: "",
        packageVolumeTwo: "m³",
        packageVolumeFour: "kg",
        volume: "",
        weight: "",
        quantity: "",
      },
      stepList: [
        {
          name: "S / C / N & Incoterm",
          zh_name: "S / C / N & 贸易条款",
        },
        {
          name: "Origin / Destination",
          zh_name: "始发地/目的地",
        },
        {
          name: "Cargo",
          zh_name: "货物",
        },
        {
          name: "Notification",
          zh_name: "通知",
        },
      ],
      groupList: [{ id: 1, packageType: "0", commodityType: "Pallet" }],
      form: {
        item11: [{}],
      },
      form0: {
        chooseRole: "0",
      },
      form1: {
        originPickUp: "0",
        originCustomerClearance: "0",
        originInsurance: "0",
        originIsfFlag: "0",
        originWarehouse: "0",
        originDocumentation: "0",
        destinationDelivery: "0",
        customerClearance: "0",
        destinationBound: "0",
        destinationWarehouse: "0",
      },
      form2: {
        cargoDetails: "1",
        totalBatteries: "0",
        hazardousMaterial: "0",
        totalOther: "0",
        totalWeight: "",
        totalVolume: "",
        totalQuantity: "",
        packings: [
          {
            id: "",
            packageName: "",
            packageValue: "",
            commodityType: "Pallet",
            packingFour: "",
            packageType: "0",
            dimensionsL: "",
            dimensionsW: "",
            dimensionsH: "",
            dimensionsOne: "m",
            dimensionsWeight: "",
            dimensionsWeightType: "kg",
            packageVolumeOne: "",
            packageVolumeThere: "",
            packageVolumeTwo: "m³",
            packageVolumeFour: "kg",
            volume: "",
            weight: "",
            quantity: "",
          },
        ],
        weightList: [
          {
            containerTypTwo: "1",
          },
        ],
        dbcgList: [{}],
      },
      form3: {},
      ContainerTypeList: [], //箱型下拉数据
      CargoTypeList: [], //货物类型下拉
      WeightUnitList: [], //重量单位
      VolumeUnitList: [], //体积单位
      VolumeUnitListForTotal: [], //总计单位体积
      CargoUnitListForTotal: [], //总计计量单位
      packUnit: [], //长度单位
      CargoUnitList: [], //计货单位
      WarehouseList: [], //仓库下拉
      CurrentyList: [], //询价单币种
      incotermsData: [], //贸易条款
      countryList: [],
      originPickUpStateList: [],
      originPickUpCityList: [],
      destinationDelivStateList: [],
      destinationDelivCityList: [],
      originPickUpStateLoading: false,
      originPickUpCityLoading: false,
      destinationDelivStateLoading: false,
      destinationDelivCityLoading: false,
      PortsList: [],
      selectLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      deliveryDayOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      rules: {
        shipper: [
          {
            required: true,
            message: this.$t("lang.SText128"),
            trigger: "blur",
          },
          {
            min: 5,
            max: 200,
            message: this.$t("lang.SText129"),
            trigger: "blur",
          },
        ],
        consignee: [
          {
            required: true,
            message: this.$t("lang.SText130"),
            trigger: "blur",
          },
          {
            min: 5,
            max: 200,
            message: this.$t("lang.SText129"),
            trigger: "blur",
          },
        ],
        notifyParty: [
          {
            required: true,
            message: this.$t("lang.SText131"),
            trigger: "blur",
          },
          {
            min: 5,
            max: 200,
            message: this.$t("lang.SText129"),
            trigger: "blur",
          },
        ],
      },
      step: 0,
      option: "0",
      checkList: [],
      QtyType: [
        {
          lable: "Pallet",
          value: "Pallet",
        },
        {
          lable: "Carton",
          value: "Carton",
        },
      ],
      showMyDraft: false,
      NotifyParty: "0",
      submitForm: {},
      saveDraftLoading: false,
    };
  },
  props: {
    quotationMainType: {
      type: String,
      default: "",
    },
    quotationType: {
      type: String,
      default: "",
    },
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
    this.init();
  },
  methods: {
    chooseRecently(val){
      var address=val.address?val.address:'';
      this.$set(this.form1,'originAddress',address);
      this.$set(this.form1,'originCountryRegion',val.country);
      this.$set(this.form1,'originState',val.state);
      this.$set(this.form1,'originCity',val.city);
      this.$set(this.form1,'originZipCode',val.zipCode);
      this.getStateList({ code: val.country },0);
      this.getCityList({ state: val.state, code: val.country },0);
    },
    chooseRecentlyDe(val){
      var address=val.address?val.address:'';
      this.$set(this.form1,'destinationAddress',address);
      this.$set(this.form1,'destinationCountryRegion',val.country);
      this.$set(this.form1,'destinationState',val.state);
      this.$set(this.form1,'destinationCity',val.city);
      this.$set(this.form1,'destinationZipCode',val.zipCode);
      this.getStateList({ code: val.country },1);
      this.getCityList({ state: val.state, code: val.country },1);
    },
    chooseRecentlyShipper(val){
      if(val.format){
        this.$set(this.form0,'shipper',val.format);
      }else{
        var address=val.address?val.address+', ':'';
        var city=val.city?val.city+', ':'';
        var state=val.state?val.state+', ':'';
        var zipCode=val.zipCode?val.zipCode+', ':'';
        var country=val.country?val.country:'';
        this.$set(this.form0,'shipper',address+city+state+zipCode+country);
      }
    },
    chooseRecentlyconsignee(val){
      if(val.format){
        this.$set(this.form0,'consignee',val.format);
      }else{
        var address=val.address?val.address+', ':'';
        var city=val.city?val.city+', ':'';
        var state=val.state?val.state+', ':'';
        var zipCode=val.zipCode?val.zipCode+', ':'';
        var country=val.country?val.country:'';
        this.$set(this.form0,'consignee',address+city+state+zipCode+country);
      }
    },
    chooseRecentlynotifyParty(val){
      if(val.format){
        this.$set(this.form0,'notifyParty',val.format);
      }else{
        var address=val.address?val.address+', ':'';
        var city=val.city?val.city+', ':'';
        var state=val.state?val.state+', ':'';
        var zipCode=val.zipCode?val.zipCode+', ':'';
        var country=val.country?val.country:'';
        this.$set(this.form0,'notifyParty',address+city+state+zipCode+country);
      }
    },
    Add(e) {
      this.form2.weightList.forEach((item, index) => {
        if (e == index) {
          if (item.containerTypTwo < 99) {
            item.containerTypTwo++;
          }
        }
      });
    },
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    limite2(e) {
      let key = e.key;
      if (key === "e") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    Prep(e) {
      this.form2.weightList.forEach((item, index) => {
        if (e == index) {
          if (item.containerTypTwo > 1) {
            item.containerTypTwo--;
          }
        }
      });
    },
    checkNotifyParty(e) {
      if (e == 1) {
        this.$set(this.form0, "notifyParty", this.form0.consignee);
      }
    },
    init() {
      this.getBaseData();
      this.$session("countryList").get((value) => {
        this.countryList = value;
      });
      this.findPortsList();
      this.findaddressList();
      this.findaddressListDe();
      this.findshipperList();
      this.findConsigneeList();
      this.findnotifyPartyList();
      
    },
    findaddressList() {
      var queryForm={
        type:'20',
        addressType: '10',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyData=res.data.data||[]
        });
    },
    findaddressListDe() {
      var queryForm={
        type:'20',
        addressType: '20',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyDataDe=res.data.data||[]
        });
    },
    findshipperList() {
      var queryForm={
        type:'shipper',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyDataShipper=res.data.data||[]
        });
    },
    findConsigneeList() {
      var queryForm={
        type:'consignee',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyDataconsignee=res.data.data||[]
        });
    },
    findnotifyPartyList() {
      var queryForm={
        type:'notify',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyDatanotifyParty=res.data.data||[]
        });
    },
    getStateList(data, num) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getStateList, data)
        .then((res) => {
          this.fromDoorStateLoading = false;
          this.toDoorStateLoading = false;
          if (res.data.result) {
            if (num == 0) {
              this.originPickUpStateList = res.data.data;
            } else if (num == 1) {
              this.destinationDelivStateList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getCityList(data, num) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getCityList, data)
        .then((res) => {
          this.originPickUpCityLoading = false;
          this.destinationDelivCityLoading = false;
          if (res.data.result) {
            if (num == 0) {
              this.originPickUpCityList = res.data.data;
            } else if (num == 1) {
              this.destinationDelivCityList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    countryChange(e, num) {
      if (num == 0) {
        this.$set(this.form1, "originState", "");
        this.$set(this.form1, "originCity", "");
      } else if (num == 1) {
        this.$set(this.form1, "destinationState", "");
        this.$set(this.form1, "destinationCity", "");
      }
      if (e) {
        this.fromDoorStateLoading = true;
        this.getStateList({ code: e }, num);
      }
    },

    stateChange(e, num) {
      if (num == 0) {
        this.$set(this.form1, "originCity", "");
        if (e) {
          this.fromDoorCityLoading = true;
          this.getCityList(
            { state: e, code: this.form1.originCountryRegion },
            num
          );
        }
      } else if (num == 1) {
        this.$set(this.form1, "destinationCity", "");
        if (e) {
          this.toDoorCityLoading = true;
          this.getCityList(
            { state: e, code: this.form1.destinationCountryRegion },
            num
          );
        }
      }
    },
    // CargoReadyDay改变时，设置deliveryDay可选范围
    CargoReadyDayChange() {
      let that = this;
      this.deliveryDayOptions = {
        disabledDate(time) {
          if (that.form1.cargoReadyDay) {
            return (
              time.getTime() < new Date(that.form1.cargoReadyDay).getTime()
            );
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    deliveryDayChange() {
      let that = this;
      this.pickerOptions = {
        disabledDate(time) {
          if (that.form1.destinationTargetDeliveryDate) {
            return (
              time.getTime() >
                new Date(that.form1.destinationTargetDeliveryDate).getTime() ||
              time.getTime() < Date.now() + 86400000 * 2
            );
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    Previous() {
      if (this.step == "0") {
        this.$emit("Previous");
        return;
      }
      this.step--;
      this.$emit("next");
    },
    //获取基础数据
    getBaseData() {
      let that = this;
      this.$session("ContainerTypeList").get((value) => {
        that.ContainerTypeList = value;
      });
      this.$session("CargoTypeList").get((value) => {
        that.CargoTypeList = value;
      });
      this.$session("WeightUnitList").get((value) => {
        that.WeightUnitList = value;
        that.$set(
          that.groupList[0],
          "dimensionsWeightType",
          value[0].dictValue
        );
        that.$set(that.groupList[0], "packageVolumeFour", value[0].dictValue);
        that.$set(that.form2, "totalWeightUnit", value[0].dictValue);
      });
      this.$session("VolumeUnitList").get((value) => {
        that.VolumeUnitList = value;
        that.$set(that.groupList[0], "dimensionsOne", value[0].dictValue);
        that.$set(that.groupList[0], "packageVolumeTwo", value[0].dictValue);
      });
      this.$session("CargoUnitList").get((value) => {
        that.CargoUnitList = value;
        that.$set(that.groupList[0], "poQtyUnit", value[0].dictValue);
      });
      this.$session("VolumeUnitListForTotal").get((value) => {
        that.VolumeUnitListForTotal = value;
        that.$set(that.form2, "totalVolumeUnit", value[0].dictValue);
      });

      this.$session("CargoUnitListForTotal").get((value) => {
        that.CargoUnitListForTotal = value;
        that.$set(that.form2, "totalQuantityUnit", value[0].dictValue);
      });
      this.$session("WarehouseList").get((value) => {
        that.WarehouseList = value;
      });
      this.$session("CurrentyList").get((value) => {
        that.CurrentyList = value;
      });
      this.$session("incotermsData").get((value) => {
        that.incotermsData = value;
      });
      this.$session("CommodityType").get((value) => {
        that.CommodityType = value;
      });
      this.$session("packUnit").get((value) => {
        this.packUnit = value;
      });
    },
    clickType(pro) {
      this.$set(this.form0, "incotermOne", pro);
      this.$set(this.form0, "incotermTwo", "");
    },
    commodityTypeChange(event, index) {
      if (event == "Carton") this.form2.packings[index].packingFour = "";
      let obj = this.form2.packings[index];
      obj.quantity = event == "Carton" ? obj.packageValue : obj.packingFour;
      obj.dimensionsOne = event == "Carton" ? "cm" : "m";
      obj.packageVolumeTwo = event == "Carton" ? "cm³" : "m³";
      this.Fun(index);
    },
    packageTypeRadio(event, index) {
      if (event == "1") {
        this.form2.packings[index].dimensionsL = "";
        this.form2.packings[index].dimensionsW = "";
        this.form2.packings[index].dimensionsH = "";
        this.form2.packings[index].dimensionsWeight = "";
      } else {
        this.form2.packings[index].packageVolumeOne = "";
        this.form2.packings[index].packageVolumeThere = "";
      }
    },
    computedValue(index) {
      this.Fun(index);
    },
    Fun(index) {
      let obj = this.form2.packings[index];
      let val = obj.packageValue;
      if (!val) {
        return;
      }
      if (obj.packageType == "0") {
        if (val && obj.dimensionsL && obj.dimensionsW && obj.dimensionsH) {
          let l = obj.dimensionsL;
          let w = obj.dimensionsW;
          let h = obj.dimensionsH;
          if (obj.dimensionsOne == "cm") {
            l = l / 100;
            w = w / 100;
            h = h / 100;
          }
          obj.volume = utils.NumberMul(
            utils.NumberMul(utils.NumberMul(val, l), w),
            h
          );
        } else {
          obj.volume = "";
        }
        if (val && obj.dimensionsWeight) {
          obj.weight = utils.NumberMul(val, obj.dimensionsWeight);
        } else {
          obj.weight = "";
        }
      } else if (obj.packageType == "1") {
        if (val && obj.packageVolumeOne) {
          let l = obj.packageVolumeOne;
          if (obj.packageVolumeTwo == "cm³") {
            l = l / 1000000;
          }
          obj.volume = utils.NumberMul(val, l);
        } else {
          obj.volume = "";
        }
        if (val && obj.packageVolumeThere) {
          obj.weight = utils.NumberMul(val, obj.packageVolumeThere);
        } else {
          obj.weight = "";
        }
      }
      if (obj.commodityType == "Pallet" && obj.packingFour) {
        obj.quantity = obj.packingFour;
      } else if (obj.commodityType == "Carton" && obj.packageValue) {
        obj.quantity = obj.packageValue;
      }
      if (obj.volume) {
        this.totalFun("totalVolume", "volume");
      }
      if (obj.weight) {
        this.totalFun("totalWeight", "weight");
      }
      if (obj.quantity) {
        this.totalFun("totalQuantity", "quantity");
      }
    },
    totalFun(str, val) {
      if (!this.countFun(this.form2.packings, val)) {
        this.form2[str] = "";
        this.form2.packings.forEach((res) => {
          this.form2[str] = utils.NumberAdd(this.form2[str], res[val]);
        });
      }
    },
    countFun(arr, str) {
      return arr.find((res) => {
        if (!res[str]) return true;
      });
    },
    addNewGroup() {
      this.form2.packings.push(JSON.parse(JSON.stringify(this.CargoTem)));
    },
    delectGroup(index) {
      this.form2.packings.splice(index, 1);
      this.totalFun("totalVolume", "volume");
      this.totalFun("totalWeight", "weight");
      this.totalFun("totalQuantity", "quantity");
    },
    addDbcg() {
      this.form2.dbcgList.push({
        commodityValue: "",
        commodityHscode: "",
      });
    },
    removeDbcg(item) {
      var index = this.form2.dbcgList.indexOf(item);
      if (index !== -1) {
        this.form2.dbcgList.splice(index, 1);
      }
    },
    addDomain() {
      this.form2.weightList.push({ containerTypOne: "", containerTypTwo: "1" });
    },
    removeDomain(data) {
      var index = this.form2.weightList.indexOf(data);
      this.ContainerTypeList.forEach((item, index) => {
        if (item.dictValue == data.containerTypOne) {
          item.disabled = false;
        }
      });
      if (index !== -1) {
        this.form2.weightList.splice(index, 1);
      }
    },
    Next() {
      this.$refs["bookingForm" + this.step].validate((valid) => {
        if (valid) {
          if (this.step == 2) {
            var aa = 1;
            this.form2.weightList.forEach((item, index) => {
              if (!item.containerTypOne || !item.containerTypTwo) {
                this.$notify.error({
                  title: this.$t("lang.SText132"),
                  offset: 100,
                  duration: 3000,
                });
                aa = 0;
              }
            });
            // this.form2.containerDatas = JSON.stringify(this.weightList);
            this.form2.containerType = this.form2.weightList;
            this.form2.dbcgList.forEach((item, index) => {
              if (!item.commodityValue) {
                this.$notify.error({
                  title: this.$t("lang.SText133"),
                  offset: 100,
                  duration: 3000,
                });
                aa = 0;
              }
            });
            // this.form2.containerDatas = JSON.stringify(this.weightList);
            this.form2.cargos = this.form2.dbcgList;
            if (
              this.form2.totalWeight < 0 ||
              this.form2.totalVolume < 0 ||
              this.form2.totalQuantity < 0
            ) {
              this.$notify.error({
                title: this.$t("lang.SText134"),
                offset: 100,
                duration: 3000,
              });
              aa = 0;
            }
            if (aa == 1) {
              this.setLabel();
              this.$emit("next");
              this.step++;
            }
          } else {
            this.setLabel();
            this.$emit("next");
            this.step++;
          }
        } else {
          return false;
        }
      });
    },
    Review() {
      this.$refs.bookingForm3.validate((valid) => {
        if (valid) {
          // this.form3.packings = JSON.stringify(this.groupList);
          this.submitForm = {
            ...{},
            ...this.form0,
            ...this.form1,
            ...this.form2,
            ...this.form3,
          };
          this.submitForm.airSea = this.quotationMainType;
          this.submitForm.bookingType = this.quotationType;
          if (this.bookingId) {
            this.submitForm.id = this.bookingId;
          }
          this.$emit("clickReview", this.submitForm);
          window.scrollTo(0, 0);
        } else {
          return false;
        }
      });
    },
    addNewGroup() {
      this.form2.packings.push(JSON.parse(JSON.stringify(this.CargoTem)));
    },
    clickSaveDraft() {
      this.showMyDraft = true;
    },
    SaveDraft(data) {
      let that = this;
      let draft = {
        ...{},
        ...this.form0,
        ...this.form1,
        ...this.form2,
        ...this.form3,
      };
      draft.airSea = this.quotationMainType;
      draft.bookingType = this.quotationType;
      that.saveDraftLoading = true;
      draft.templateType = "1";
      draft.serialNumber = data.serialNumber;
      draft.id = data.id;
      if (draft.containerType) {
        draft.containerType = JSON.stringify(draft.containerType);
      }
      if (draft.packings) {
        draft.packings = JSON.stringify(draft.packings);
      }
      if (draft.cargos) {
        draft.cargos = JSON.stringify(draft.cargos);
      }
      that.$ajax
        .cPost(that.source.booking_api.addTemplateDraft, draft)
        .then((res) => {
          that.saveDraftLoading = false;
          if (res.data.result) {
            that.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            that.showMyDraft = false;
          } else {
            that.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    Cancel() {
      this.showMyDraft = false;
    },
    findPortsList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.findPortsList, {
          dictLabel: e,
        })
        .then((res) => {
          this.selectLoading = false;
          if ((res.resultCode = "success")) {
            this.PortsList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    //重量选择改变时改变不可选状态
    weightSelectChange(val) {
      this.ContainerTypeList.forEach((item) => {
        item.disabled = false;
        this.form2.weightList.forEach((item2) => {
          if (item.dictValue == item2.containerTypOne) {
            item.disabled = true;
          }
        });
      });
    },
    setLabel() {
      if (this.step == 1) {
        this.form1.originPortName = this.$refs.originPort.selectedLabel;
        this.form1.destinationDischargePortName =
          this.$refs.destinationDischargePort.selectedLabel;
        if (this.form1.originPickUp == 1) {
          this.form1.originCountryRegionName =
            this.$refs.originCountryRegion.selectedLabel;
          this.form1.originStateName = this.$refs.originState.selectedLabel;
          this.form1.originCityName = this.$refs.originCity.selectedLabel;
        }
        if (this.form1.destinationDelivery == 1) {
          this.form1.destinationCountryRegionName =
            this.$refs.destinationCountryRegion.selectedLabel;
          this.form1.destinationStateName =
            this.$refs.destinationState.selectedLabel;
          this.form1.destinationCityName =
            this.$refs.destinationCity.selectedLabel;
        }
      }
      if (this.step == 2) {
        this.form2.cargoTypeName = this.$refs.cargoType.selectedLabel;
        this.form2.cargos.forEach((item, index) => {
          item.commodityValueName =
            this.$refs.commodityValue[index].selectedLabel;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.booking-create-step {
  text-align: left;
  color: $mainColor;
  .el-form-item {
    display: flex;
    flex-direction: column;
  }

  .packingList {
    .group {
      padding-bottom: 20px;
      border-bottom: 2px solid $borderColor;
      padding-top: 10px;
      position: relative;
    }
    .group-title {
      transform: rotate(90deg);
      position: absolute;
      width: 100px;
      height: 20px;
      left: -40px;
      top: 50px;
      // margin-left: -190px;
      color: $fontColor1;
      font-family: "montserratregular";
    }
    .group-main {
      width: 750px;
      margin-left: 50px;
      .group-line {
        display: flex;
        margin: 15px 0;
        .Dimensions-input {
          display: flex;
          margin-right: 20px;
          .input100 {
            width: 110px;
          }
          .select120 {
            width: 120px;
          }
        }
        .Lenght-title {
          display: flex;
          line-height: 25px;
        }
        .Lenght-title1,
        .Lenght-title2 {
          width: 110px;
        }
        .Lenght-title3 {
          width: 160px;
        }
        .Lenght-title4 {
          width: 160px;
        }
        .Lenght-title5 {
          width: 300px;
        }
        .QtyType {
          font-size: 14px;
          margin: 0 5px;
          line-height: 25px;
        }
        .QtyType1 {
          font-size: 14px;
          width: 100px;
          line-height: 25px;
        }
        .QtyType2 {
          font-size: 14px;
          margin: 0 5px;
          line-height: 25px;
        }
        .group-item {
          .group-item-title {
            color: $fontColor2;
            margin-bottom: 10px;
          }
          .width100 {
            width: 100px;
          }
        }
      }
    }

    .delect {
      color: $background2;
      width: 47px;
      position: absolute;
      bottom: 40px;
      right: 0;
    }
    .addButton {
      height: 48px;
      width: 180px;
      line-height: 48px;
      color: $background2;
      margin: 20px 0;
      text-align: center;
      background-color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-input__inner {
    height: 48px;
  }

  .el-input {
    height: 48px;
  }

  .timeline {
    background-color: $background1;
    padding: 20px;
    border: 1px solid $borderColor1;
    padding-left: 210px;
    .step {
      min-width: 250px !important;
    }
  }

  .Unit-select {
    width: 120px;
  }

  .stepDiv {
    .step {
      color: $background2;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    .skip {
      font-size: 30px;
      margin-left: 60px;
      color: $background2;
      text-decoration: underline;
    }
    .step-title {
      font-size: 60px;
      color: $mainColor;
      text-align: left;
      padding-left: 30px;
      padding: 10px 30px;
      display: flex;
      .optional {
        font-size: 30px;
        color: #b3b3b3;
        line-height: 100px;
        margin-left: 20px;
      }
    }
  }

  .booking-create-form {
    width: 900px;

    margin-left: 210px;
    padding: 50px 0;
  }

  .radio {
    font-size: 18px;
    padding-left: 15px;

    .el-radio__label {
      line-height: 30px;
    }
    .el-form-item__content {
      display: flex;
    }
  }
  .form-item-title1 {
    color: $mainColor;
    border-bottom: 2px solid $fontColor1;
    padding-bottom: 8px;
    width: 70%;
  }

  .form-item-title {
    color: $fontColor1;
    font-size: 20px;
    padding-left: 15px;
  }

  .form-line {
    display: flex;
    justify-content: space-between;
    .form-item {
      width: 48%;
      position: relative;
    }
  }

  .el-textarea__inner {
    // padding-top: 30px;
  }

  .input-title {
    position: absolute;
    top: 0px;
    z-index: 9;
    color: $fontColor1;
    left: 10px;
    right: 0;
    display: flex;
    .user {
      font-size: 12px;
      color: #fff;
      background-color: $background2;
      height: 16px;
      line-height: 16px;
      margin: 10px 20px;
      padding: 0 2px;
    }
  }

  .radioTitle {
    font-size: 20px;
  }

  .Date-line {
    display: flex;
    justify-content: space-between;
  }

  .bottom-button {
    display: flex;
    justify-content: end;
    margin-top: 40px;
    .el-button {
      margin-left: 20px;
    }
  }

  .step1-select {
    width: 48%;
  }

  .date-card {
    width: 48%;
  }
  .date-card-select {
    width: 100%;
  }
  .input48 {
    width: 48%;
  }

  .Incoterms-radio {
    margin-left: 100px;
  }

  .el-date-editor {
    width: 100%;
  }

  .weightItem {
    .el-form-item__content {
      display: flex;
      .weight-select {
        width: 200px;
      }
      .el-button--text {
        font-size: 20px;
        color: $background2;
      }
      .weightInput {
        width: 140px;
        margin-left: -1px;
        margin-right: 20px;
        .chenghao {
          line-height: 48px;
          font-size: 20px;
          padding-left: 5px;
          color: $mainColor;
        }
      }
    }
    .add {
      position: absolute;
      left: 309px;
      top: 0;
      width: 30px;
      height: 24px;
      background-color: $borderColor1;
      .svg-icon {
        position: absolute;
        height: 10px;
        left: 8px;
        top: 5px;
        line-height: 10px;
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
    .add:hover,
    .prep:hover {
      background-color: $fontColor3;
    }
    .prep {
      position: absolute;
      left: 309px;
      top: 24px;
      width: 30px;
      height: 24px;
      color: $fontColor1;
      background-color: $borderColor1;
      .svg-icon {
        position: absolute;
        height: 25px;
        left: 8px;
        top: 2px;
        line-height: 10px;
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
  }

  .OriginServices-checkbox {
    display: flex;
    flex-direction: column;
    .svg-icon {
      margin-right: 10px;
    }
    .HazardousMaterials {
      .svg-icon {
        stroke: none;
      }
    }
  }

  .Package-details {
    display: flex;
    .add {
    }
  }

  .quotation-detials-checkbox {
    display: flex;
    .el-checkbox__label {
      font-size: 20px !important;
      color: $mainColor;
      padding: 10px 20px;
    }
  }

  .checkbox-content {
    margin: 0 30px;
    .form-item-title {
      color: $fontColor1;
      line-height: 40px;
    }
  }

  .insurance-line {
    display: flex;
    .insurance-input {
      width: 250px;
      margin-right: 20px;
    }
    .insurance-select {
      width: 135px;
    }
  }
  .bond-radio {
    display: flex;
    margin-bottom: 30px;
  }

  .right-button {
    .add {
      height: 20px;
      line-height: 18px;
      width: 20px;
      text-align: center;
      border: 1px solid $background2;
      margin-right: 10px;
    }
  }

  .required {
    color: $mainColor;
  }

  .incotermsType {
    display: flex;
    flex-direction: row;
  }
}
</style>