<template>
  <div class="booking-edit" id="booking-edit" v-loading="editLoading">
    <div class="stepDiv step1">
      <el-form
        class="booking-create-form"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.BText77") }}</div>
            <div class="main">
              <el-form-item class="form-item" prop="referenceNo">
                <div class="form-item-title">{{ $t("lang.BText78") }}</div>
                <el-input style="width: 50%" v-model="form.referenceNo">
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">
              {{ $t("lang.BText17") }} / {{ $t("lang.BText3") }} /
              {{ $t("lang.BText22") }}
            </div>
            <div class="main">
              <!-- <el-form-item class="form-item radio" prop="chooseRole">
                <div class="radioTitle">My role in this shipment：</div>
                <el-radio v-model="form.chooseRole" label="0">
                  Shipper
                </el-radio>
                <el-radio v-model="form.chooseRole" label="1">
                  Consignee
                </el-radio>
              </el-form-item> -->
              <el-form-item
                class="form-item"
                prop="shipper"
                :label="$t('lang.BText17')"
              >
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover"
                >
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li
                        v-for="(item, index) in recentlyDataShipper"
                        :key="index"
                        @click="chooseRecentlyShipper(item)"
                      >
                        <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{ item.country }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="form.shipper"
                    maxlength="200"
                    resize="none"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover"
                >
                  <ul class="RecentlyUl">
                    <li
                      v-for="(item, index) in recentlyDataShipper"
                      :key="index"
                      @click="chooseRecentlyShipper(item)"
                    >
                      <div v-if="item.format">
                        <span>{{ item.format }}</span>
                      </div>
                      <div v-else>
                        <span v-if="item.address">{{ item.address }}, </span>
                        <span v-if="item.city">{{ item.city }}, </span>
                        <span v-if="item.state">{{ item.state }}, </span>
                        <span v-if="item.country">{{ item.country }}</span>
                      </div>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <el-form-item
                class="form-item"
                prop="consignee"
                :label="$t('lang.BText3')"
              >
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover"
                >
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li
                        v-for="(item, index) in recentlyDataconsignee"
                        :key="index"
                        @click="chooseRecentlyconsignee(item)"
                      >
                        <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{ item.country }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="form.consignee"
                    resize="none"
                    maxlength="200"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover"
                >
                  <ul class="RecentlyUl">
                    <li
                      v-for="(item, index) in recentlyDataconsignee"
                      :key="index"
                      @click="chooseRecentlyconsignee(item)"
                    >
                      <div v-if="item.format">
                        <span>{{ item.format }}</span>
                      </div>
                      <div v-else>
                        <span v-if="item.address">{{ item.address }}, </span>
                        <span v-if="item.city">{{ item.city }}, </span>
                        <span v-if="item.state">{{ item.state }}, </span>
                        <span v-if="item.country">{{ item.country }}</span>
                      </div>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <!-- <el-checkbox
                v-model="NotifyParty"
                class="quotation-detials-checkbox"
                label="Notify party is same as consignee"
              ></el-checkbox> -->
              <el-form-item
                class="form-item"
                prop="notifyParty"
                :label="$t('lang.BText22')"
              >
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover"
                >
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li
                        v-for="(item, index) in recentlyDatanotifyParty"
                        :key="index"
                        @click="chooseRecentlynotifyParty(item)"
                      >
                        <div v-if="item.format">
                          <span>{{ item.format }}</span>
                        </div>
                        <div v-else>
                          <span v-if="item.address">{{ item.address }}, </span>
                          <span v-if="item.city">{{ item.city }}, </span>
                          <span v-if="item.state">{{ item.state }}, </span>
                          <span v-if="item.country">{{ item.country }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="form.notifyParty"
                    resize="none"
                    maxlength="200"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover"
                >
                  <ul class="RecentlyUl">
                    <li
                      v-for="(item, index) in recentlyDatanotifyParty"
                      :key="index"
                      @click="chooseRecentlynotifyParty(item)"
                    >
                      <div v-if="item.format">
                        <span>{{ item.format }}</span>
                      </div>
                      <div v-else>
                        <span v-if="item.address">{{ item.address }}, </span>
                        <span v-if="item.city">{{ item.city }}, </span>
                        <span v-if="item.state">{{ item.state }}, </span>
                        <span v-if="item.country">{{ item.country }}</span>
                      </div>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
            </div>
          </div>

          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText38") }}</div>
            <div class="main">
              <el-form-item
                class="form-item"
                prop="incotermOne"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText98'),
                    trigger: 'blur',
                  },
                ]"
              >
                <div class="radiobox">
                  <div class="text">{{ $t("lang.QText39") }}</div>
                  <div
                    class="option"
                    :class="{
                      optionFocus:
                        form.incotermOne != '' && form.incotermOne == 0,
                    }"
                    @click="clickType('0')"
                  >
                    {{ $t("lang.QText40") }}
                  </div>
                  <div
                    class="option"
                    :class="{
                      optionFocus:
                        form.incotermOne != '' && form.incotermOne == 1,
                    }"
                    @click="clickType('1')"
                  >
                    {{ $t("lang.QText41") }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                class="form-item radio Incoterms-radio incotermsType"
                prop="incotermTwo"
                v-if="
                  form.incotermOne != '' &&
                  (form.incotermOne == 0 || form.incotermOne == 1)
                "
                label=" "
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText98'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio-group v-model="form.incotermTwo">
                  <template v-if="form.incotermOne == 0">
                    <el-radio
                      :label="item.dictValue"
                      v-for="item in incotermsData.buyData"
                      :key="item.dictValue"
                    >
                      {{ item.dictLabel }}</el-radio
                    >
                  </template>
                  <template v-if="form.incotermOne == 1">
                    <el-radio
                      :label="item.dictValue"
                      v-for="item in incotermsData.sellerData"
                      :key="item.dictValue"
                    >
                      {{ item.dictLabel }}</el-radio
                    >
                  </template>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </div>

        <div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.BText23") }}</div>
            <div class="main">
              <el-form-item
                class="form-item"
                prop="originPort"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText100'),
                    trigger: 'blur',
                  },
                ]"
                :label="$t('lang.BText24')"
              >
                <!-- <div class="form-item-title">Original port</div> -->
                <el-select
                  class="step1-select"
                  v-model="form.originPort"
                  :placeholder="$t('lang.BText80')"
                  filterable
                  remote
                  clearable
                  ref="originPort"
                  :remote-method="findPortsList"
                  :loading="selectLoading"
                  @change="setLabel('originPort')"
                  :disabled="bookingData.quotationNo != undefined"
                >
                  <el-option
                    v-for="item in PortsList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="cargoReadyDay"
                  :label="$t('lang.QText21')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText101'),
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="form.cargoReadyDay"
                    type="date"
                    :placeholder="$t('lang.BText81')"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    @change="CargoReadyDayChange"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div class="form-item-title">{{ $t("lang.BText124") }}</div>
              <!-- <el-checkbox-group class="OriginServices-checkbox" v-model="checkList"> -->
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText49')"
                v-model="form.originPickUp"
                true-label="1"
                false-label="0"
                v-if="
                  (form.originPickUp == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <div class="checkbox-content" v-if="form.originPickUp == 1">
                <!-- <div class="form-item-title">Address</div> -->
                <el-form-item
                  class="form-item"
                  prop="originAddress"
                  :label="$t('lang.QText50')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText102'),
                    },
                  ]"
                >
                  <el-popover
                    placement="bottom"
                    popper-class="Recentlypopover"
                    trigger="hover"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                    <div class="Recentlyused">
                      <p>最近常用</p>
                      <ul>
                        <li
                          v-for="(item, index) in recentlyData"
                          :key="index"
                          @click="chooseRecently(item)"
                        >
                          <span v-show="item.address"
                            >{{ item.address }},
                          </span>
                          <span v-show="item.cityShow"
                            >{{ item.cityShow }},
                          </span>
                          <span v-show="item.stateShow"
                            >{{ item.stateShow }},
                          </span>
                          <span v-show="item.countryShow">{{
                            item.countryShow
                          }}</span>
                        </li>
                      </ul>
                    </div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      :placeholder="$t('lang.BText41')"
                      v-model="form.originAddress"
                      resize="none"
                      maxlength="100"
                      slot="reference"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                    </el-input>
                  </el-popover>
                  <el-popover
                    placement="left"
                    popper-class="RecentlypopoverRight"
                    trigger="hover"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                    <ul class="RecentlyUl">
                      <li
                        v-for="(item, index) in recentlyData"
                        :key="index"
                        @click="chooseRecently(item)"
                      >
                        <span v-show="item.address">{{ item.address }}, </span>
                        <span v-show="item.cityShow"
                          >{{ item.cityShow }},
                        </span>
                        <span v-show="item.stateShow"
                          >{{ item.stateShow }},
                        </span>
                        <span v-show="item.countryShow">{{
                          item.countryShow
                        }}</span>
                      </li>
                    </ul>
                    <i class="el-icon-more more" slot="reference"></i>
                  </el-popover>
                </el-form-item>
                <div class="form-line">
                  <el-form-item
                    class="form-item"
                    prop="originCountryRegion"
                    :label="$t('lang.QText52')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.SText161'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <div class="form-item-title">Country or region</div> -->
                    <el-select
                      class="date-card-select"
                      v-model="form.originCountryRegion"
                      :placeholder="$t('lang.BText80')"
                      @change="countryChange($event, 0, 'originCountryRegion')"
                      filterable
                      ref="originCountryRegion"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                      <el-option
                        v-for="item in countryList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    class="form-item"
                    prop="originState"
                    :label="$t('lang.QText53')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.SText160'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <div class="form-item-title">State</div> -->
                    <el-select
                      class="date-card-select"
                      v-model="form.originState"
                      :placeholder="$t('lang.BText80')"
                      @change="stateChange($event, 0, 'originState')"
                      filterable
                      ref="originState"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                      <el-option
                        v-for="item in originPickUpStateList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="form-line">
                  <el-form-item
                    class="form-item"
                    prop="originCity"
                    :label="$t('lang.QText54')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.SText159'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <div class="form-item-title">City</div> -->
                    <el-select
                      class="date-card-select"
                      v-model="form.originCity"
                      :placeholder="$t('lang.BText80')"
                      filterable
                      ref="originCity"
                      @change="setLabel('originCity')"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                      <el-option
                        v-for="item in originPickUpCityList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="form-item" prop="originZipCode">
                    <div class="form-item-title">{{ $t("lang.QText55") }}</div>
                    <el-input
                      v-model="form.originZipCode"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </div>

              <el-checkbox
                class="quotation-detials-checkbox"
                v-model="form.originCustomerClearance"
                :label="$t('lang.BText25')"
                true-label="1"
                false-label="0"
                v-if="
                  (form.originCustomerClearance == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                v-model="form.originInsurance"
                :label="$t('lang.QText57')"
                true-label="1"
                false-label="0"
                v-if="
                  (form.originInsurance == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <div
                class="insurance-line checkbox-content"
                v-if="form.originInsurance == 1"
              >
                <el-form-item
                  class="form-item"
                  prop="originValue"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText103'),
                    },
                  ]"
                  :label="$t('lang.QText58')"
                >
                  <!-- <div class="form-item-title">Value</div> -->
                  <el-input
                    class="insurance-input"
                    v-model="form.originValue"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="originCurrency"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText104'),
                      trigger: 'blur',
                    },
                  ]"
                  :label="$t('lang.QText59')"
                >
                  <!-- <div class="form-item-title">Currency</div> -->
                  <el-select
                    class="insurance-select"
                    v-model="form.originCurrency"
                    :placeholder="$t('lang.BText80')"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                    <el-option
                      v-for="item in CurrentyList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!-- <el-checkbox class="quotation-detials-checkbox" label="Bond"></el-checkbox>
                                <div class="checkbox-content">
                                    <div class="bond-radio">
                                        <el-radio v-model="form.item14" label="1">
                                            Single bond
                                        </el-radio>
                                        <el-radio v-model="form.item14" label="2">
                                            Annual bond
                                        </el-radio>
                                    </div>
                                </div> -->

              <el-checkbox
                class="quotation-detials-checkbox"
                v-model="form.originIsfFlag"
                label="ISF"
                true-label="1"
                false-label="0"
                v-if="
                  (form.originIsfFlag == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <el-checkbox
                v-if="
                  (form.originAmsFlag == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                class="quotation-detials-checkbox"
                label="AMS"
                v-model="form.originAmsFlag"
                true-label="1"
                false-label="0"
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <el-checkbox
                v-if="
                  (form.originAciFlag == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                class="quotation-detials-checkbox"
                label="ACI"
                v-model="form.originAciFlag"
                true-label="1"
                false-label="0"
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                v-model="form.originDocumentation"
                :label="$t('lang.QText61')"
                true-label="1"
                false-label="0"
                v-if="
                  (form.originDocumentation == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                v-model="form.originWarehouse"
                :label="$t('lang.QText63')"
                true-label="1"
                false-label="0"
                v-if="
                  (form.originWarehouse == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <div class="checkbox-content" v-if="form.originWarehouse == 1">
                <el-form-item
                  class="form-item"
                  prop="originValueAddedSevice"
                  :label="$t('lang.QText64')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText105'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- <div class="form-item-title">Value added services</div> -->
                  <el-select
                    class="date-card-select"
                    v-model="form.originValueAddedSevice"
                    :placeholder="$t('lang.BText80')"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                    <el-option
                      v-for="item in WarehouseList.originWarehouse"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!-- </el-checkbox-group> -->
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.BText26") }}</div>
            <div class="main">
              <el-form-item
                class="form-item"
                prop="destinationDischargePort"
                :label="$t('lang.BText27')"
              >
                <el-select
                  class="step1-select"
                  v-model="form.destinationDischargePort"
                  :placeholder="$t('lang.BText80')"
                  ref="destinationDischargePort"
                  @change="setLabel('destinationDischargePort')"
                  remote
                  clearable
                  filterable
                  :remote-method="findPortsList"
                  :loading="selectLoading"
                  :disabled="form.quotationNo != undefined"
                >
                  <el-option
                    v-for="item in PortsList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  :label="$t('lang.BText103')"
                  prop="destinationTargetDeliveryDate"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText107'),
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="form.destinationTargetDeliveryDate"
                    type="date"
                    :placeholder="$t('lang.BText81')"
                    :picker-options="deliveryDayOptions"
                    @change="deliveryDayChange"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>

              <div class="form-item-title">{{ $t("lang.BText125") }}</div>
              <el-checkbox
                class="quotation-detials-checkbox"
                v-model="form.destinationDelivery"
                :label="$t('lang.QText67')"
                true-label="1"
                false-label="0"
                v-if="
                  (form.destinationDelivery == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <div
                class="checkbox-content"
                v-if="form.destinationDelivery == 1"
              >
                <el-form-item
                  class="form-item"
                  prop="destinationAddress"
                  :label="$t('lang.QText50')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText108'),
                    },
                  ]"
                >
                  <el-popover
                    placement="bottom"
                    popper-class="Recentlypopover"
                    trigger="hover"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                    <div class="Recentlyused">
                      <p>最近常用</p>
                      <ul>
                        <li
                          v-for="(item, index) in recentlyDataDe"
                          :key="index"
                          @click="chooseRecentlyDe(item)"
                        >
                          <span v-show="item.address"
                            >{{ item.address }},
                          </span>
                          <span v-show="item.cityShow"
                            >{{ item.cityShow }},
                          </span>
                          <span v-show="item.stateShow"
                            >{{ item.stateShow }},
                          </span>
                          <span v-show="item.countryShow">{{
                            item.countryShow
                          }}</span>
                        </li>
                      </ul>
                    </div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      :placeholder="$t('lang.BText41')"
                      v-model="form.destinationAddress"
                      resize="none"
                      maxlength="100"
                      slot="reference"
                      :disabled="bookingData.quotationNo != undefined"
                    ></el-input>
                  </el-popover>
                  <el-popover
                    placement="left"
                    popper-class="RecentlypopoverRight"
                    trigger="hover"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                    <ul class="RecentlyUl">
                      <li
                        v-for="(item, index) in recentlyDataDe"
                        :key="index"
                        @click="chooseRecentlyDe(item)"
                      >
                        <span v-show="item.address">{{ item.address }}, </span>
                        <span v-show="item.cityShow"
                          >{{ item.cityShow }},
                        </span>
                        <span v-show="item.stateShow"
                          >{{ item.stateShow }},
                        </span>
                        <span v-show="item.countryShow">{{
                          item.countryShow
                        }}</span>
                      </li>
                    </ul>
                    <i class="el-icon-more more" slot="reference"></i>
                  </el-popover>
                </el-form-item>
                <!-- <div class="form-item-title">Address</div> -->
                <div class="form-line">
                  <el-form-item
                    class="form-item"
                    prop="destinationCountryRegion"
                    :label="$t('lang.QText52')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.SText161'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <div class="form-item-title">Country or region</div> -->
                    <el-select
                      class="date-card-select"
                      v-model="form.destinationCountryRegion"
                      :placeholder="$t('lang.BText80')"
                      @change="
                        countryChange($event, 1, 'destinationCountryRegion')
                      "
                      filterable
                      ref="destinationCountryRegion"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                      <el-option
                        v-for="item in countryList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    class="form-item"
                    prop="destinationState"
                    :label="$t('lang.QText53')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.SText160'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <div class="form-item-title">State</div> -->
                    <el-select
                      class="date-card-select"
                      v-model="form.destinationState"
                      :placeholder="$t('lang.BText80')"
                      @change="stateChange($event, 1, 'destinationState')"
                      filterable
                      ref="destinationState"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                      <el-option
                        v-for="item in destinationDelivStateList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="form-line">
                  <el-form-item
                    class="form-item"
                    prop="destinationCity"
                    :label="$t('lang.QText54')"
                    :rules="[
                      {
                        required: true,
                        message: $t('lang.SText159'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <div class="form-item-title">City</div> -->
                    <el-select
                      class="date-card-select"
                      v-model="form.destinationCity"
                      :placeholder="$t('lang.BText80')"
                      filterable
                      ref="destinationCity"
                      @change="setLabel('destinationCity')"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                      <el-option
                        v-for="item in destinationDelivCityList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="form-item" prop="originZipCode">
                    <div class="form-item-title">{{ $t("lang.QText55") }}</div>
                    <el-input
                      v-model="form.destinationZipCode"
                      :disabled="bookingData.quotationNo != undefined"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.BText25')"
                v-model="form.customerClearance"
                true-label="1"
                false-label="0"
                v-if="
                  (form.customerClearance == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText69')"
                v-model="form.destinationBound"
                true-label="1"
                false-label="0"
                v-if="
                  (form.destinationBound == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <div class="checkbox-content" v-if="form.destinationBound == 1">
                <el-form-item
                  class="form-item bond-radio incotermsType"
                  prop="destinationBoundValue"
                  label=" "
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText110'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-radio-group v-model="form.destinationBoundValue">
                    <el-radio label="Single bond">
                      {{ $t("lang.QText70") }}
                    </el-radio>
                    <el-radio label="Annual bond">
                      {{ $t("lang.QText71") }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText63')"
                v-model="form.destinationWarehouse"
                true-label="1"
                false-label="0"
                v-if="
                  (form.destinationWarehouse == 1 &&
                    bookingData.quotationNo != undefined) ||
                  !bookingData.quotationNo
                "
                :disabled="bookingData.quotationNo != undefined"
              ></el-checkbox>
              <div
                class="checkbox-content"
                v-if="form.destinationWarehouse == 1"
              >
                <el-form-item
                  class="form-item"
                  prop="destinationWarehouseValue"
                  :label="$t('lang.QText64')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText105'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- <div class="form-item-title">Value added services</div> -->
                  <el-select
                    class="date-card-select"
                    v-model="form.destinationWarehouseValue"
                    :placeholder="$t('lang.BText80')"
                    :disabled="bookingData.quotationNo != undefined"
                  >
                    <el-option
                      v-for="item in WarehouseList.destWarehouse"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText26") }}</div>
            <div class="main">
              <div class="form-title" style="margin-bottom: 10px">
                * {{ $t("lang.BText82") }}
              </div>
              <el-form-item
                v-for="(item, index) in form.containerType"
                :key="index"
                class="weightItem unspin"
                :prop="'containerType.' + index + '.containerTypOne'"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText96'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  class="weight-select"
                  v-model="item.containerTypOne"
                  placeholder=""
                  @change="weightSelectChange"
                  :disabled="bookingData.quotationNo != undefined"
                >
                  <el-option
                    v-for="el in ContainerTypeList"
                    :key="el.dictValue"
                    :label="el.dictLabel"
                    :value="el.dictValue"
                    :disabled="el.disabled"
                  ></el-option>
                </el-select>
                <el-input
                  class="weightInput"
                  type="number"
                  @keydown.native="limite2"
                  v-model="item.containerTypTwo"
                  min="1"
                  max="99"
                  maxlength="2"
                  @input="
                    item.containerTypTwo = item.containerTypTwo.replace(
                      /[^\d]/g,
                      ''
                    )
                  "
                  :disabled="bookingData.quotationNo != undefined"
                >
                  <div slot="prefix" class="chenghao">X</div>
                </el-input>
                <div
                  class="add"
                  @click="Add(index)"
                  v-if="!bookingData.quotationNo"
                >
                  <svg-icon icon-class="up"></svg-icon>
                </div>
                <div
                  class="prep"
                  @click="Prep(index)"
                  v-if="!bookingData.quotationNo"
                >
                  <svg-icon icon-class="down"></svg-icon>
                </div>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click.prevent="removeDomain('containerType', index)"
                  v-if="
                    form.containerType.length > 1 && !bookingData.quotationNo
                  "
                ></el-button>
                <el-button
                  v-if="
                    index == form.containerType.length - 1 &&
                    index < ContainerTypeList.length - 1 &&
                    !bookingData.quotationNo
                  "
                  type="text"
                  @click.prevent="addDomain('containerType')"
                  >{{ $t("lang.BText83") }}
                </el-button>
              </el-form-item>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.BText104") }}</div>
            <div class="main">
              <el-form-item
                class="form-item"
                prop="cargoType"
                :label="$t('lang.QText43')"
              >
                <!-- <div class="form-item-title" style="color: #808080">
                  Cargo type
                </div> -->
                <el-select
                  class="step1-select"
                  v-model="form.cargoType"
                  :placeholder="$t('lang.BText80')"
                  ref="cargoType"
                  @change="setLabel('cargoType')"
                  style="width: 50%"
                >
                  <el-option
                    v-for="item in CargoTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div class="form-item-title required" style="margin-bottom: 10px">
                * {{ $t("lang.QText47") }}
              </div>
              <div
                class="weightItem form-item"
                v-for="(item, index) in form.cargos"
                :key="index"
              >
                <el-form-item
                  :prop="'cargos.' + index + '.commodityValue'"
                  :rules="{
                    required: true,
                    message: $t('lang.SText133'),
                    trigger: ['blur', 'change'],
                  }"
                  class="Commdity-form"
                >
                  <el-select
                    class="weight-select"
                    v-model="item.commodityValue"
                    placeholder=""
                    ref="commodityValue"
                    @change="setLabel('commodityValue', index)"
                    style="width: 50%"
                    filterable
                  >
                    <el-option
                      v-for="item in CommodityType"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-input
                  class="weightInput"
                  v-model="item.commodityHscode"
                  :placeholder="$t('lang.BText84')"
                ></el-input>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click.prevent="removeDomain('cargos', index)"
                  v-if="form.cargos.length > 1"
                ></el-button>
                <el-button
                  v-if="index == form.cargos.length - 1"
                  type="text"
                  @click.prevent="addDomain('cargos')"
                  >{{ $t("lang.BText42") }}</el-button
                >
              </div>

              <el-form-item
                class="form-item radio"
                prop="cargoDetails"
                style="border-bottom: 1px solid #e6e6e6"
              >
                <div class="radioTitle">{{ $t("lang.QText42") }}：</div>
                <el-radio v-model="form.cargoDetails" label="1">
                  {{ $t("lang.BText43") }}
                </el-radio>
                <el-radio v-model="form.cargoDetails" label="0">
                  {{ $t("lang.BText44") }}
                </el-radio>
              </el-form-item>
              <div class="packingList unspin" v-if="form.cargoDetails == 0">
                <div
                  class="group"
                  v-for="(group, index) in form.packings"
                  :key="index"
                >
                  <div class="group-title">Group {{ index + 1 }}</div>
                  <div class="group-main">
                    <div class="group-line">
                      <div class="group-item">
                        <div class="group-item-title">
                          {{ $t("lang.BText45") }}
                        </div>
                        <el-input
                          style="width: 90%; margin-right: 20px"
                          v-model="group.packageName"
                        >
                        </el-input>
                      </div>
                      <div class="group-item">
                        <div class="group-item-title required">
                          * {{ $t("lang.BText46") }}
                        </div>
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.packageValue'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="width100"
                              v-model="group.packageValue"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="weight-select"
                            v-model="group.commodityType"
                            @change="
                              ($event) => {
                                commodityTypeChange($event, index);
                              }
                            "
                            placeholder=""
                            style="width: 150px"
                          >
                            <el-option
                              v-for="item in QtyType"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div
                        class="group-item"
                        style="width: 170px"
                        v-if="group.commodityType == 'Pallet'"
                      >
                        <div class="group-item-title required">
                          * {{ $t("lang.BText85") }}
                        </div>
                        <el-form-item
                          label=""
                          :prop="'packings.' + index + '.packingFour'"
                          :rules="{
                            required: true,
                            message: $t('lang.text8'),
                            trigger: 'blur',
                          }"
                        >
                          <el-input
                            style="width: 100px"
                            v-model="group.packingFour"
                            @input="computedValue(index)"
                            type="number"
                            @keydown.native="limite"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="group-line">
                      <el-radio
                        v-model="group.packageType"
                        @change="
                          ($event) => {
                            packageTypeRadio($event, index);
                          }
                        "
                        label="0"
                      >
                        {{ $t("lang.BText86") }}
                      </el-radio>
                      <el-radio
                        v-model="group.packageType"
                        @change="
                          ($event) => {
                            packageTypeRadio($event, index);
                          }
                        "
                        label="1"
                      >
                        {{ $t("lang.BText87") }}
                      </el-radio>
                    </div>
                    <template v-if="group.packageType == 0">
                      <div class="group-line">
                        <div class="Lenght-title Lenght-title1">
                          * {{ $t("lang.BText88") }}
                        </div>
                        <div class="Lenght-title Lenght-title2">
                          * {{ $t("lang.BText89") }}
                        </div>
                        <div class="Lenght-title Lenght-title3">
                          * {{ $t("lang.BText90") }}
                        </div>
                        <div class="QtyType1">/ {{ group.commodityType }}</div>
                        <div class="Lenght-title Lenght-title4">
                          * {{ $t("lang.BText48") }}
                        </div>
                        <div class="QtyType2">/ {{ group.commodityType }}</div>
                      </div>
                      <div class="group-line">
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsL'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsL"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsW'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsW"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsH'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsH"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="select120"
                            v-model="group.dimensionsOne"
                            placeholder=""
                            disabled
                          >
                            <el-option
                              v-for="item in packUnit"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled != undefined"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.dimensionsWeight'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              class="input100"
                              v-model="group.dimensionsWeight"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="select120"
                            v-model="group.dimensionsWeightType"
                            placeholder=""
                          >
                            <el-option
                              v-for="item in WeightUnitList"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled != undefined"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </template>
                    <template v-if="group.packageType == 1">
                      <div class="group-line">
                        <div class="Lenght-title Lenght-title5">
                          * {{ $t("lang.BText47") }}
                          <div class="QtyType">({{ group.commodityType }})</div>
                        </div>
                        <div class="Lenght-title">
                          * {{ $t("lang.BText48") }}
                          <div class="QtyType">({{ group.commodityType }})</div>
                        </div>
                      </div>
                      <div class="group-line">
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.packageVolumeOne'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              style="width: 100px"
                              v-model="group.packageVolumeOne"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="weight-select"
                            v-model="group.packageVolumeTwo"
                            placeholder=""
                            style="width: 150px"
                            disabled
                          >
                            <el-option
                              v-for="item in VolumeUnitList"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled != undefined"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="Dimensions-input">
                          <el-form-item
                            label=""
                            :prop="'packings.' + index + '.packageVolumeThere'"
                            :rules="{
                              required: true,
                              message: $t('lang.text8'),
                              trigger: 'blur',
                            }"
                          >
                            <el-input
                              style="width: 100px"
                              v-model="group.packageVolumeThere"
                              @input="computedValue(index)"
                              type="number"
                              @keydown.native="limite2"
                            ></el-input>
                          </el-form-item>
                          <el-select
                            class="weight-select"
                            v-model="group.packageVolumeFour"
                            placeholder=""
                            style="width: 150px"
                          >
                            <el-option
                              v-for="item in WeightUnitList"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                              :disabled="item.disabled != undefined"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div
                    class="delect"
                    v-if="form.packings.length > 1"
                    @click="delectGroup(index)"
                  >
                    <svg-icon icon-class="delete"></svg-icon>
                  </div>
                </div>
                <div class="addButton" @click="addNewGroup()">
                  {{ $t("lang.BText49") }}
                </div>
              </div>
              <div class="total unspin">
                <el-form-item class="form-item" prop="totalWeight">
                  <div class="form-item-title required">
                    * {{ $t("lang.QText28") }}
                  </div>
                  <el-input
                    class="input48"
                    v-model="form.totalWeight"
                    type="number"
                    @keydown.native="limite2"
                  >
                    <template slot="append"
                      ><el-select
                        class="Unit-select"
                        v-model="form.totalWeightUnit"
                        placeholder=""
                      >
                        <el-option
                          v-for="item in WeightUnitList"
                          :key="item.value"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                          :disabled="item.disabled != undefined"
                        >
                        </el-option> </el-select
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item class="form-item" prop="totalVolume">
                  <div class="form-item-title required">
                    * {{ $t("lang.QText29") }}
                  </div>
                  <el-input
                    class="input48"
                    v-model="form.totalVolume"
                    type="number"
                    @keydown.native="limite2"
                    ><template slot="append"
                      ><el-select
                        class="Unit-select"
                        v-model="form.totalVolumeUnit"
                        placeholder=""
                      >
                        <el-option
                          v-for="item in VolumeUnitListForTotal"
                          :key="item.value"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                          :disabled="item.disabled != undefined"
                        >
                        </el-option> </el-select
                    ></template>
                  </el-input>
                </el-form-item>

                <el-form-item class="form-item" prop="totalQuantity">
                  <div class="form-item-title required">
                    * {{ $t("lang.QText30") }}
                  </div>
                  <el-input
                    class="input48"
                    type="number"
                    v-model="form.totalQuantity"
                    @keydown.native="limite"
                    ><template slot="append"
                      ><el-select
                        class="Unit-select"
                        v-model="form.totalQuantityUnit"
                        placeholder=""
                        disabled
                      >
                        <el-option
                          v-for="item in CargoUnitListForTotal"
                          :key="item.value"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                          :disabled="item.disabled != undefined"
                        >
                        </el-option> </el-select
                    ></template>
                  </el-input>
                </el-form-item>
                <div
                  class="form-item-title"
                  style="margin-bottom: 10px; color: #808080"
                >
                  {{ $t("lang.BText51") }}
                </div>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  :placeholder="$t('lang.BText41')"
                  v-model="form.totalAddress"
                >
                </el-input>
                <div
                  class="form-item-title"
                  v-show="
                    form.totalBatteries == 1 ||
                    form.hazardousMaterial == 1 ||
                    form.totalOther == 1
                  "
                  style="
                    margin-top: 30px;
                    padding-top: 30px;
                    border-top: 1px solid #e6e6e6;
                    margin-bottom: 10px;
                  "
                >
                  {{ $t("lang.BText52") }}
                </div>
                <el-checkbox
                  v-model="form.totalBatteries"
                  true-label="1"
                  false-label="0"
                  class="quotation-detials-checkbox"
                  :disabled="bookingData.quotationNo != undefined"
                  v-if="
                    (form.totalBatteries == 1 &&
                      bookingData.quotationNo != undefined) ||
                    !bookingData.quotationNo
                  "
                >
                  <svg-icon icon-class="batteries"></svg-icon>
                  {{ $t("lang.QText32") }}
                </el-checkbox>
                <el-checkbox
                  v-model="form.hazardousMaterial"
                  true-label="1"
                  false-label="0"
                  class="quotation-detials-checkbox"
                  :disabled="bookingData.quotationNo != undefined"
                  v-if="
                    (form.hazardousMaterial == 1 &&
                      bookingData.quotationNo != undefined) ||
                    !bookingData.quotationNo
                  "
                >
                  <svg-icon icon-class="HazardousMaterials"></svg-icon>
                  {{ $t("lang.QText33") }}
                </el-checkbox>
                <el-checkbox
                  v-model="form.totalOther"
                  true-label="1"
                  false-label="0"
                  class="quotation-detials-checkbox"
                  :disabled="bookingData.quotationNo != undefined"
                  v-if="
                    (form.totalOther == 1 &&
                      bookingData.quotationNo != undefined) ||
                    !bookingData.quotationNo
                  "
                >
                  <svg-icon icon-class="other"></svg-icon>
                  {{ $t("lang.QText34") }}
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="main">
            <div class="dw-card2">
              <div class="title">{{ $t("lang.BText31") }}</div>
              <div class="main">
                <el-form-item class="form-item">
                  <div class="group-line">
                    <el-radio
                      v-model="form.notification"
                      label="Just my account"
                    >
                      {{ $t("lang.BText53") }}
                    </el-radio>
                    <el-radio
                      v-model="form.notification"
                      label="Multi-party cooperation"
                    >
                      {{ $t("lang.BText54") }}
                    </el-radio>
                  </div>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="pricingBookingComfirmation"
                  v-show="form.notification == 'Just my account'"
                >
                  <div class="form-item-title">
                    {{ $t("lang.BText32") }}
                  </div>
                  <el-input
                    :placeholder="$t('lang.BText92')"
                    class="input48"
                    v-model="form.pricingBookingComfirmation"
                  >
                  </el-input>
                </el-form-item>
                <template v-if="form.notification == 'Multi-party cooperation'">
                  <div class="form-item-title1">{{ $t("lang.BText91") }}</div>
                  <div class="checkbox-content">
                    <div class="form-item-title">
                      {{ $t("lang.BText92") }}
                      <span style="font-size: 12px"
                        >{{ $t("lang.BText93") }}
                      </span>
                    </div>
                    <el-form-item class="form-item" prop="bookingComfirmation">
                      <el-input
                        class="input48"
                        v-model="form.bookingComfirmation"
                        style="width: 100%"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="form-item-title1">{{ $t("lang.BText94") }}</div>
                  <div class="checkbox-content">
                    <el-form-item class="form-item" prop="pricingOriginCharge">
                      <div class="form-item-title">
                        {{ $t("lang.BText92") }}
                      </div>
                      <el-input
                        class="input48"
                        style="width: 60%"
                        v-model="form.pricingOriginCharge"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="form-item-title1">
                    {{ $t("lang.BText95") }}
                  </div>
                  <div class="checkbox-content">
                    <el-form-item
                      class="form-item"
                      prop="freightDestinationCharge"
                    >
                      <div class="form-item-title">
                        {{ $t("lang.BText92") }}
                      </div>
                      <el-input
                        style="width: 60%"
                        class="input48"
                        v-model="form.freightDestinationCharge"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </template>
              </div>
            </div>

            <!-- <div class="dw-card2">
              <div class="title">Remark</div>
              <div class="main">
                <el-form-item class="form-item" prop="notificationRemark">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    placeholder="Yo! Leave a message, or something..."
                    v-model="form.notificationRemark"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div> -->
          </div>
        </div>

        <div class="bottom-button">
          <dw-button type="infoSmall" @click="cancel()">{{
            $t("lang.QText79")
          }}</dw-button>
          <dw-button type="primarySmall" @click="Review()">{{
            $t("lang.QText76")
          }}</dw-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import dwTimeLine from "../../components/dwTimeline/dwTimeLine.vue";
import utils from "../../utils/util.js";
export default {
  components: {
    dwTimeLine,
  },
  props: {
    bookingId: {
      type: String,
      default: () => "",
    },
    bookingData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      NotifyParty: false,
      SaveLoading: false,
      groupList: [{ id: 1, Dimensions: "1", item7: "Pallet" }],
      incotermsData: [], //贸易条款
      PortsList: [],
      WarehouseList: [], //仓库下拉
      CurrentyList: [], //询价单币种
      ContainerTypeList: [], //箱型下拉数据
      CargoTypeList: [], //货物类型下拉
      CommodityType: [], //货物类型下拉
      VolumeUnitList: [], //体积单位
      VolumeUnitListForTotal: [], //总计单位体积
      CargoUnitListForTotal: [], //总计计量单位
      WeightUnitList: [], //重量单位
      CargoUnitList: [], //计货单位
      packUnit: [], //长度单位
      countryList: [],
      originPickUpStateList: [],
      destinationDelivStateList: [],
      originPickUpCityList: [],
      destinationDelivCityList: [],
      selectLoading: false,
      address: [
        { name: "CNSHA | Shanghai,China" },
        { name: "CNSHA | Shanghai,China" },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      deliveryDayOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      recentlyData: [],
      recentlyDataShipper: [],
      recentlyDataconsignee: [],
      recentlyDataDe: [],

      recentlyDatanotifyParty: [],
      CargoTem: {
        id: "",
        packageName: "",
        packageValue: "",
        commodityType: "Pallet",
        packingFour: "",
        packageType: "0",
        dimensionsL: "",
        dimensionsW: "",
        dimensionsH: "",
        dimensionsOne: "m",
        dimensionsWeight: "",
        dimensionsWeightType: "kg",
        packageVolumeOne: "",
        packageVolumeThere: "",
        packageVolumeTwo: "m³",
        packageVolumeFour: "kg",
        volume: "",
        weight: "",
        quantity: "",
      },
      form: {
        bookingStatus: "",
        bookingType: "",
        cargoDetails: "",
        cargoReadyDay: "",
        cargoReadyDayOne: "",
        cargoReadyDayTwo: "",
        cargoType: "",
        cargos: [{ commodityValue: "", commodityHscode: "" }],
        chooseRole: "",
        companyId: "",
        consignee: "",
        containerType: [{ containerTypOne: "", containerTypTwo: "" }],
        createBy: "",
        createDate: "",
        customerClearance: "",
        destinationDelivery: "",
        destinationDischargePort: "",
        destinationTargetDeliveryDate: "",
        id: "",
        incotermOne: "",
        incotermTwo: "",
        isNewRecord: false,
        notification: "",
        notifyParty: "",
        originAddress: "",
        originBond: "",
        originCity: "",
        originCountryRegion: "",
        originIsfFlag: "",
        originPickUp: "",
        originPort: "",
        originState: "",
        originZipCode: "",
        // packings: [JSON.parse(JSON.stringify(this.CargoTem))],
        packings: [],
        pricingBookingComfirmation: "@163.com",
        shipper: "",
        status: "",
        totalBatteries: "",
        totalQuantity: "",
        totalVolume: "",
        totalVolumeUnit: "",
        totalWeight: "",
        totalWeightUnit: "",
        updateBy: "",
        updateDate: "",
      },
      rules: {
        shipper: [
          {
            required: true,
            message: this.$t("lang.SText128"),
            trigger: "blur",
          },
          {
            min: 5,
            max: 200,
            message: this.$t("lang.SText129"),
            trigger: "blur",
          },
        ],
        consignee: [
          {
            required: true,
            message: this.$t("lang.SText130"),
            trigger: "blur",
          },
          {
            min: 5,
            max: 200,
            message: this.$t("lang.SText129"),
            trigger: "blur",
          },
        ],
        notifyParty: [
          {
            required: true,
            message: this.$t("lang.SText131"),
            trigger: "blur",
          },
          {
            min: 5,
            max: 200,
            message: this.$t("lang.SText129"),
            trigger: "blur",
          },
        ],
        totalVolume: [
          {
            required: true,
            message: this.$t("lang.SText135"),
            trigger: "blur",
          },
          {
            pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
            message: this.$t("lang.QText96"),
            trigger: "blur",
          },
        ],
        totalWeight: [
          {
            required: true,
            message: this.$t("lang.SText136"),
            trigger: "blur",
          },
          {
            pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
            message: this.$t("lang.QText96"),
            trigger: "blur",
          },
        ],
        totalQuantity: [
          {
            required: true,
            message: this.$t("lang.SText137"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 10,
            message: this.$t("lang.QText93"),
            trigger: ["blur", "change"],
          },
        ],
        originPort: [
          {
            required: true,
            message: this.$t("lang.NText100"),
            trigger: "blur",
          },
        ],
        destinationDischargePort: [
          {
            required: true,
            message: this.$t("lang.SText139"),
            trigger: "blur",
          },
        ],
        cargoType: [
          {
            required: true,
            message: this.$t("lang.SText140"),
            trigger: "blur",
          },
        ],
      },
      step: "0",
      option: "0",
      weightList: [{}],
      weight: [
        {
          lable: "40",
          value: "40 ft",
        },
        {
          lable: "30",
          value: "30 ft",
        },
        {
          lable: "20",
          value: "20 ft",
        },
        {
          lable: "10",
          value: "10 ft",
        },
      ],
      checkList: [],
      cargoDetails: "0",
      QtyType: [
        {
          lable: "Pallet",
          value: "Pallet",
        },
        {
          lable: "Carton",
          value: "Carton",
        },
      ],
      editLoading: false,
      userInfo: {},
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
    this.$session("incotermsData").get((value) => {
      this.incotermsData = value;
    });
    this.$session("countryList").get((value) => {
      this.countryList = value;
    });
    this.$session("WarehouseList").get((value) => {
      this.WarehouseList = value;
    });
    this.$session("CurrentyList").get((value) => {
      this.CurrentyList = value;
    });
    this.$session("ContainerTypeList").get((value) => {
      this.ContainerTypeList = value;
    });
    this.$session("CargoTypeList").get((value) => {
      this.CargoTypeList = value;
    });
    this.$session("CommodityType").get((value) => {
      this.CommodityType = value;
    });
    this.$session("packUnit").get((value) => {
      this.packUnit = value;
    });
    this.$session("VolumeUnitList").get((value) => {
      this.VolumeUnitList = value;
      this.$set(this.groupList[0], "dimensionsOne", value[0].dictValue);
      this.$set(this.groupList[0], "packageVolumeTwo", value[0].dictValue);
    });
    this.$session("WeightUnitList").get((value) => {
      this.WeightUnitList = value;
      this.$set(this.groupList[0], "dimensionsWeightType", value[0].dictValue);
      this.$set(this.groupList[0], "packageVolumeFour", value[0].dictValue);
      this.$set(this.form, "totalWeightUnit", value[0].dictValue);
    });
    this.$session("CargoUnitList").get((value) => {
      this.CargoUnitList = value;
      this.$set(this.groupList[0], "poQtyUnit", value[0].dictValue);
    });
    this.$session("VolumeUnitListForTotal").get((value) => {
      this.VolumeUnitListForTotal = value;
      this.$set(this.form, "totalVolumeUnit", value[0].dictValue);
    });

    this.$session("CargoUnitListForTotal").get((value) => {
      this.CargoUnitListForTotal = value;
      this.$set(this.form, "totalQuantityUnit", value[0].dictValue);
    });
    this.$set(this, "form", this.bookingData);
    this.NotifyParty = this.form.notifyParty ? true : false;
    if (this.form.cargoDetails == "1" && !this.form.packings) {
      this.$set(this.form, "packings", [
        JSON.parse(JSON.stringify(this.CargoTem)),
      ]);
      // this.$set(this.form, "packings", [this.CargoTem]);
    } else {
      this.form.packings.forEach((value, index) => {
        this.Fun(index, true);
      });
    }
    if (this.form.containerType.length > 0) {
      this.weightSelectChange();
    } else {
      this.form.containerType = [];
      this.addDomain("containerType");
    }
    if (!this.form.cargos || this.form.cargos.length == 0) {
      this.form.cargos = [
        {
          commodityValue: this.form.commodityHscodes,
          commodityHscode: "",
          commodityValueName: this.form.commodityHscodesName,
        },
      ];
      // this.addDomain("cargos");
    }
    if (this.$refs["ruleForm"]) {
      this.$nextTick(function () {
        this.$refs["ruleForm"].clearValidate();
      });
    }

    this.getAllAddress();
    // if (this.bookingId) {
    //   this.bookingDetails();
    // } else if (this.bookingData) {
    //   this.form = this.bookingData;
    // }
  },
  methods: {
    chooseRecently(val) {
      var address = val.address ? val.address : "";
      this.$set(this.form, "originAddress", address);
      this.$set(this.form, "originCountryRegion", val.country);
      this.$set(this.form, "originState", val.state);
      this.$set(this.form, "originCity", val.city);
      this.$set(this.form, "originZipCode", val.zipCode);
      this.getStateList({ code: val.country }, 0);
      this.getCityList({ state: val.state, code: val.country }, 0);
    },
    chooseRecentlyDe(val) {
      var address = val.address ? val.address : "";
      this.$set(this.form, "destinationAddress", address);
      this.$set(this.form, "destinationCountryRegion", val.country);
      this.$set(this.form, "destinationState", val.state);
      this.$set(this.form, "destinationCity", val.city);
      this.$set(this.form, "destinationZipCode", val.zipCode);
      this.getStateList({ code: val.country }, 1);
      this.getCityList({ state: val.state, code: val.country }, 1);
    },
    chooseRecentlyShipper(val) {
      if (val.format) {
        this.$set(this.form, "shipper", val.format);
      } else {
        var address = val.address ? val.address + ", " : "";
        var city = val.city ? val.city + ", " : "";
        var state = val.state ? val.state + ", " : "";
        var zipCode = val.zipCode ? val.zipCode + ", " : "";
        var country = val.country ? val.country : "";
        this.$set(
          this.form,
          "shipper",
          address + city + state + zipCode + country
        );
      }
    },
    chooseRecentlyconsignee(val) {
      if (val.format) {
        this.$set(this.form, "consignee", val.format);
      } else {
        var address = val.address ? val.address + ", " : "";
        var city = val.city ? val.city + ", " : "";
        var state = val.state ? val.state + ", " : "";
        var zipCode = val.zipCode ? val.zipCode + ", " : "";
        var country = val.country ? val.country : "";
        this.$set(
          this.form,
          "consignee",
          address + city + state + zipCode + country
        );
      }
    },
    chooseRecentlynotifyParty(val) {
      if (val.format) {
        this.$set(this.form, "notifyParty", val.format);
      } else {
        var address = val.address ? val.address + ", " : "";
        var city = val.city ? val.city + ", " : "";
        var state = val.state ? val.state + ", " : "";
        var zipCode = val.zipCode ? val.zipCode + ", " : "";
        var country = val.country ? val.country : "";
        this.$set(
          this.form,
          "notifyParty",
          address + city + state + zipCode + country
        );
      }
    },
    Add(e) {
      this.form.containerType.forEach((item, index) => {
        if (e == index) {
          if (item.containerTypTwo < 99) {
            item.containerTypTwo++;
          }
        }
      });
    },
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    limite2(e) {
      let key = e.key;
      if (key === "e") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    Prep(e) {
      this.form.containerType.forEach((item, index) => {
        if (e == index) {
          if (item.containerTypTwo > 1) {
            item.containerTypTwo--;
          }
        }
      });
    },
    bookingDetails() {
      this.editLoading = true;
      this.$ajax
        .cPost(this.source.booking_api.bookingDetails, { id: this.bookingId })
        .then((res) => {
          if (res.data.result == "true") {
            if (res.data.bookingMain.containerType == "") {
              this.addDomain("containerType");
            } else {
              res.data.bookingMain.containerType = JSON.parse(
                res.data.bookingMain.containerType.replace("/", "")
              );
            }
            console.log(res.data.bookingMain.containerType);
            // res.data.bookingMain.cargos = JSON.parse(res.data.bookingMain.cargos);
            // res.data.bookingMain.cargos = [{commodityValue:'', commodityHscode: ''}];
            this.NotifyParty = res.data.bookingMain.notifyParty ? true : false;
            if (res.data.bookingMain.cargoDetails == "1") {
              res.data.bookingMain.packings.push(
                JSON.parse(JSON.stringify(this.CargoTem))
              );
            }
            this.form = res.data.bookingMain;
            console.log(this.form);
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.editLoading = false;
        });
    },
    findPortsList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.findPortsList, {
          dictLabel: e,
        })
        .then((res) => {
          this.selectLoading = false;
          if ((res.resultCode = "success")) {
            this.PortsList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    countryChange(e, num, str) {
      if (num == 0) {
        this.form.originState = "";
        this.form.originCity = "";
      } else if (num == 1) {
        this.form.destinationState = "";
        this.form.destinationCity = "";
      }
      if (e) {
        this.getStateList({ code: e }, num);
      }
      this.setLabel(str);
    },
    getStateList(data, num) {
      this.$ajax
        .cPost(this.source.address_api.getStateList, data)
        .then((res) => {
          this.fromDoorStateLoading = false;
          this.toDoorStateLoading = false;
          if (res.data.result) {
            if (num == 0) {
              this.originPickUpStateList = res.data.data;
            } else if (num == 1) {
              this.destinationDelivStateList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    stateChange(e, num, str) {
      if (num == 0) {
        this.form.originCity = "";
        if (e) {
          this.getCityList(
            { state: e, code: this.form.originCountryRegion },
            num
          );
        }
      } else if (num == 1) {
        this.form.destinationCity = "";
        if (e) {
          this.getCityList(
            { state: e, code: this.form.destinationCountryRegion },
            num
          );
        }
      }
      this.setLabel(str);
    },
    getCityList(data, num) {
      this.$ajax
        .cPost(this.source.address_api.getCityList, data)
        .then((res) => {
          if (res.data.result) {
            if (num == 0) {
              this.originPickUpCityList = res.data.data;
            } else if (num == 1) {
              this.destinationDelivCityList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    weightSelectChange(val) {
      this.ContainerTypeList.forEach((item) => {
        item.disabled = false;
        this.form.containerType.forEach((item2) => {
          if (item.dictValue == item2.containerTypOne) {
            item.disabled = true;
          }
        });
      });
    },
    commodityTypeChange(event, index) {
      if (event == "Carton") this.form.packings[index].packingFour = "";
      let obj = this.form.packings[index];
      obj.quantity = event == "Carton" ? obj.packageValue : obj.packingFour;
      obj.dimensionsOne = event == "Carton" ? "cm" : "m";
      obj.packageVolumeTwo = event == "Carton" ? "cm³" : "m³";
      this.Fun(index);
    },
    packageTypeRadio(event, index) {
      if (event == "1") {
        this.form.packings[index].dimensionsL = "";
        this.form.packings[index].dimensionsW = "";
        this.form.packings[index].dimensionsH = "";
        this.form.packings[index].dimensionsWeight = "";
      } else {
        this.form.packings[index].packageVolumeOne = "";
        this.form.packings[index].packageVolumeThere = "";
      }
    },
    computedValue(index) {
      this.Fun(index);
    },
    Fun(index, init) {
      let obj = this.form.packings[index];
      let val = obj.packageValue;
      if (!val) {
        return;
      }
      if (obj.packageType == "0") {
        if (val && obj.dimensionsL && obj.dimensionsW && obj.dimensionsH) {
          let l = obj.dimensionsL;
          let w = obj.dimensionsW;
          let h = obj.dimensionsH;
          if (obj.dimensionsOne == "cm") {
            l = l / 100;
            w = w / 100;
            h = h / 100;
          }
          obj.volume = utils.NumberMul(
            utils.NumberMul(utils.NumberMul(val, l), w),
            h
          );
          console.log(obj);
        } else {
          obj.volume = "";
        }
        if (val && obj.dimensionsWeight) {
          obj.weight = utils.NumberMul(val, obj.dimensionsWeight);
        } else {
          obj.weight = "";
        }
      } else if (obj.packageType == "1") {
        if (val && obj.packageVolumeOne) {
          let l = obj.packageVolumeOne;
          if (obj.packageVolumeTwo == "cm³") {
            l = l / 1000000;
          }
          obj.volume = utils.NumberMul(val, l);
        } else {
          obj.volume = "";
        }
        if (val && obj.packageVolumeThere) {
          obj.weight = utils.NumberMul(val, obj.packageVolumeThere);
        } else {
          obj.weight = "";
        }
      }
      if (obj.commodityType == "Pallet" && obj.packingFour) {
        obj.quantity = obj.packingFour;
      } else if (obj.commodityType == "Carton" && obj.packageValue) {
        obj.quantity = obj.packageValue;
      }
      if (obj.volume && !init) {
        this.totalFun("totalVolume", "volume");
      }
      if (obj.weight && !init) {
        this.totalFun("totalWeight", "weight");
      }
      if (obj.quantity && !init) {
        this.totalFun("totalQuantity", "quantity");
      }
    },
    totalFun(str, val) {
      if (!this.countFun(this.form.packings, val)) {
        this.form[str] = "";
        this.form.packings.forEach((res) => {
          this.form[str] = utils.NumberAdd(this.form[str], res[val]);
        });
      }
    },
    countFun(arr, str) {
      return arr.find((res) => {
        if (!res[str]) return true;
      });
    },
    addNewGroup() {
      console.log(this.CargoTem);
      this.form.packings.push(JSON.parse(JSON.stringify(this.CargoTem)));
    },
    delectGroup(index) {
      this.form.packings.splice(index, 1);
      this.totalFun("totalVolume", "volume");
      this.totalFun("totalWeight", "weight");
      this.totalFun("totalQuantity", "quantity");
    },
    cancel() {
      this.$emit("cancelEdit");
    },
    Review() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log(this.form.cargos);
          for (let i = 0; i < this.form.cargos.length; i++) {
            this.setLabel("commodityValue", i);
          }
          this.form.totalVolume = Number(this.form.totalVolume).toFixed(3);
          this.form.totalWeight = Number(this.form.totalWeight).toFixed(3);
          let obj = JSON.parse(JSON.stringify(this.form));
          this.$emit("clickReview", obj);
        }
      });
      return;
    },
    clickType(pro) {
      this.form.incotermOne = pro;
    },
    removeDomain(type, index) {
      this.form[type].splice(index, 1);
      this.weightSelectChange();
    },
    addDomain(type) {
      let obj;
      if (type == "containerType") {
        obj = { containerTypOne: "", containerTypTwo: "1" };
      } else {
        obj = { commodityValue: "", commodityHscode: "" };
      }
      this.form[type].push(obj);
    },
    setLabel(str, index) {
      if (str == "commodityValue") {
        this.form.cargos[index].commodityValueName =
          this.$refs.commodityValue[index].selectedLabel;
        return;
      }
      let that = this;
      setTimeout(() => {
        this.form[str + "Name"] = that.$refs[str].selectedLabel;
      }, 10);
    },
    // CargoReadyDay改变时，设置deliveryDay可选范围
    CargoReadyDayChange() {
      let that = this;
      this.deliveryDayOptions = {
        disabledDate(time) {
          if (that.form.cargoReadyDay) {
            return time.getTime() < new Date(that.form.cargoReadyDay).getTime();
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    deliveryDayChange() {
      let that = this;
      this.pickerOptions = {
        disabledDate(time) {
          if (that.form.destinationTargetDeliveryDate) {
            return (
              time.getTime() >
                new Date(that.form.destinationTargetDeliveryDate).getTime() ||
              time.getTime() < Date.now() + 86400000 * 2
            );
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    // 初始化地址下拉
    getAllAddress() {
      if (
        this.form.originCountryRegion &&
        this.form.originCountryRegion !== ""
      ) {
        this.getStateList({ code: this.form.originCountryRegion }, 0);
        let params = {
          state: this.form.originState,
          code: this.form.originCountryRegion,
        };
        this.getCityList(params, 0);
      }
      if (
        this.form.destinationCountryRegion &&
        this.form.destinationCountryRegion !== ""
      ) {
        this.getStateList({ code: this.form.destinationCountryRegion }, 1);
        let params = {
          state: this.form.destinationState,
          code: this.form.destinationCountryRegion,
        };
        this.getCityList(params, 1);
      }
    },
    findaddressList() {
      var queryForm = {
        type: "20",
        addressType: "10",
        companyId: this.userInfo.companyid,
      };
      this.$ajax.cPost(this.source.address_api.fill, queryForm).then((res) => {
        this.recentlyData = res.data.data || [];
      });
    },
    findaddressListDe() {
      var queryForm = {
        type: "20",
        addressType: "20",
        companyId: this.userInfo.companyid,
      };
      this.$ajax.cPost(this.source.address_api.fill, queryForm).then((res) => {
        this.recentlyDataDe = res.data.data || [];
      });
    },
    findshipperList() {
      var queryForm = {
        type: "shipper",
        companyId: this.userInfo.companyid,
      };
      this.$ajax.cPost(this.source.address_api.fill, queryForm).then((res) => {
        this.recentlyDataShipper = res.data.data || [];
      });
    },
    findConsigneeList() {
      var queryForm = {
        type: "consignee",
        companyId: this.userInfo.companyid,
      };
      this.$ajax.cPost(this.source.address_api.fill, queryForm).then((res) => {
        this.recentlyDataconsignee = res.data.data || [];
      });
    },
    findnotifyPartyList() {
      var queryForm = {
        type: "notify",
        companyId: this.userInfo.companyid,
      };
      this.$ajax.cPost(this.source.address_api.fill, queryForm).then((res) => {
        this.recentlyDatanotifyParty = res.data.data || [];
      });
    },
  },
  mounted() {
    this.findPortsList();
    this.findaddressList();
    this.findaddressListDe();
    this.findshipperList();
    this.findConsigneeList();
    this.findnotifyPartyList();
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.booking-edit {
  text-align: left;
  color: $mainColor;
  .el-form-item {
    display: flex;
    flex-direction: column;
  }

  .packingList {
    .group {
      padding-bottom: 20px;
      border-bottom: 2px solid $borderColor;
      padding-top: 10px;
      position: relative;
    }
    .group-title {
      transform: rotate(90deg);
      position: absolute;
      width: 100px;
      height: 20px;
      left: -40px;
      top: 50px;
      // margin-left: -190px;
      color: $fontColor1;
      font-family: "montserratregular";
    }
    .group-main {
      width: 750px;
      margin-left: 50px;
      .group-line {
        display: flex;
        margin: 15px 0;
        .Dimensions-input {
          display: flex;
          margin-right: 20px;
          .input100 {
            width: 110px;
          }
          .select120 {
            width: 120px;
          }
        }
        .Lenght-title {
          display: flex;
          line-height: 25px;
        }
        .Lenght-title1,
        .Lenght-title2 {
          width: 110px;
        }
        .Lenght-title3 {
          width: 160px;
        }
        .Lenght-title4 {
          width: 160px;
        }
        .Lenght-title5 {
          width: 300px;
        }
        .QtyType {
          font-size: 14px;
          margin: 0 5px;
          line-height: 25px;
        }
        .QtyType1 {
          font-size: 14px;
          width: 100px;
          line-height: 25px;
        }
        .QtyType2 {
          font-size: 14px;
          margin: 0 5px;
          line-height: 25px;
        }
        .group-item {
          .group-item-title {
            color: $fontColor2;
            margin-bottom: 10px;
          }
          .width100 {
            width: 100px;
          }
        }
      }
    }

    .delect {
      color: $background2;
      width: 47px;
      position: absolute;
      bottom: 40px;
      right: 0;
    }
    .addButton {
      height: 48px;
      width: 180px;
      line-height: 48px;
      color: $background2;
      margin: 20px 0;
      text-align: center;
      background-color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .el-input__inner {
    height: 48px;
  }

  .el-input {
    height: 48px;
  }

  .stepDiv {
    .step {
      color: $background2;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    .skip {
      font-size: 30px;
      margin-left: 60px;
      color: $background2;
      text-decoration: underline;
    }
    .step-title {
      font-size: 60px;
      color: $mainColor;
      text-align: left;
      padding-left: 30px;
      padding: 10px 30px;
      display: flex;
      .optional {
        font-size: 30px;
        color: #b3b3b3;
        line-height: 100px;
        margin-left: 20px;
      }
    }
  }

  .booking-create-form {
    width: 900px;

    margin-left: 210px;
    padding: 50px 0;
  }

  .radio {
    font-size: 18px;
    padding-left: 15px;

    .el-radio__label {
      line-height: 30px;
    }
    .el-form-item__content {
      display: flex;
    }
  }
  .form-item-title1 {
    color: $mainColor;
    border-bottom: 2px solid $fontColor1;
    padding-bottom: 8px;
    width: 70%;
  }

  .form-item-title {
    color: $fontColor2;
    font-size: 20px;
    padding-left: 15px;
  }

  .el-textarea__inner {
    // padding-top: 30px;
  }

  .input-title {
    position: absolute;
    top: 40px;
    z-index: 9;
    color: $fontColor1;
    left: 10px;
    right: 0;
    display: flex;
    .user {
      font-size: 12px;
      color: #fff;
      background-color: $background2;
      height: 16px;
      line-height: 16px;
      margin: 10px 20px;
      padding: 0 2px;
    }
  }

  .radioTitle {
    font-size: 20px;
  }

  .Date-line {
    display: flex;
    justify-content: space-between;
  }

  .bottom-button {
    display: flex;
    justify-content: end;
    .el-button {
      margin-left: 20px;
    }
  }

  .step1-select {
    width: 48%;
  }

  .date-card {
    width: 48%;
    .date-card-select {
      width: 100%;
    }
  }

  .input48 {
    width: 48%;
  }

  .Unit-select {
    width: 120px;
  }

  .Incoterms-radio {
    margin-left: 60px;
  }

  .el-date-editor {
    width: 100%;
  }

  .weightItem {
    display: flex;
    .Commdity-form {
      width: 280px;
      .weight-select {
        width: 279px !important;
      }
    }
    .el-form-item__content {
      .el-button--text {
        font-size: 20px;
        color: $background2;
      }
    }
    .weightInput {
      width: 140px;
      margin-left: -1px;
      margin-right: 20px;
      .chenghao {
        line-height: 48px;
        font-size: 20px;
        padding-left: 5px;
        color: $mainColor;
      }
    }
    .add {
      position: absolute;
      left: 379px;
      top: 0;
      width: 30px;
      height: 24px;
      color: $fontColor1;
      background-color: $borderColor1;
      .svg-icon {
        position: absolute;
        height: 10px;
        left: 8px;
        top: 5px;
        line-height: 10px;
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
    .add:hover,
    .prep:hover {
      background-color: $fontColor3;
    }
    .prep {
      position: absolute;
      left: 379px;
      top: 24px;
      width: 30px;
      height: 24px;
      background-color: $borderColor1;
      .svg-icon {
        position: absolute;
        height: 25px;
        left: 8px;
        top: 2px;
        line-height: 10px;
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
  }

  .OriginServices-checkbox {
    display: flex;
    flex-direction: column;
    .svg-icon {
      color: $background2;
      margin-right: 10px;
      fill: $background2;
      stroke: $background2;
    }
  }

  .Package-details {
    display: flex;
    .add {
    }
  }

  .quotation-detials-checkbox {
    display: flex;
    .el-checkbox__label {
      font-size: 20px !important;
      color: $mainColor;
      padding: 10px 20px;
      .svg-icon {
        margin-top: -10px;
      }
    }
  }

  .checkbox-content {
    margin: 0 30px;
    .form-item-title {
      color: $fontColor1;
      line-height: 40px;
    }
    .form-line {
      .el-select {
        width: 100%;
      }
    }
  }
  .form-line {
    display: flex;
    justify-content: space-between;
    .form-item {
      width: 48%;
      position: relative;
    }
  }

  .insurance-line {
    display: flex;
    .insurance-input {
      width: 250px;
      margin-right: 20px;
    }
    .insurance-select {
      width: 135px;
    }
  }
  .bond-radio {
    display: flex;
    margin-bottom: 30px;
  }

  .right-button {
    .add {
      height: 20px;
      line-height: 18px;
      width: 20px;
      text-align: center;
      border: 1px solid $background2;
      margin-right: 10px;
    }
  }

  .required {
    color: $mainColor !important;
  }
  .incotermsType {
    display: flex;
    flex-direction: row;
  }
}
</style>
