<template>
  <div class="booking-create">
    <div class="title">{{$t('lang.BText75')}}</div>
    <div class="typeList">
      <transition
        v-for="(item, index) in quotationTypeList"
        :key="item.dictValue"
        :name="showType[index] ? 'active' + index : 'quotationType' + index"
      >
        <div
          class="type-icon-main"
          @click="clickType(index, item)"
          v-show="item.show"
          :style="{ left: 1.25 * index + 'rem' }"
          :ref="'quotationType' + index"
          :class="{ noneType: index > 0 }"
        >
          <svg-icon :icon-class="item.cssClass" class="svg-icon"></svg-icon>
          <div class="type-title">{{ item.dictLabel }}</div>
        </div>
      </transition>
      <div
        v-for="(item, index) in quotationTypeList"
        :key="item.dictValue"
        class="type-item"
        v-show="showType2[index]"
      >
        <div class="type-icon-main">
          <svg-icon :icon-class="item.cssClass" class="svg-icon"></svg-icon>
          <div class="type-title">{{ item.dictLabel }}</div>
        </div>
        <transition name="hoverDiv">
          <div class="children" v-show="showType2[index]">
            <div class="radio">
              <el-radio
                v-model="quotationType"
                :label="item2.dictValue"
                v-for="item2 in item.children"
                :key="item2.dictValue"
                class="radio-item"
              >
                <svg-icon :icon-class="item2.dictValue"></svg-icon
                >{{ item2.dictValue }} </el-radio
              ><svg-icon
                icon-class="left"
                class="left-icon"
                @click="close"
              ></svg-icon>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="Quote">
      <el-button type="primary" v-show="showQuote" @click="quote()"
        >{{$t('lang.BText35')}}</el-button
      >
    </div>

    <div class="or">{{$t('lang.BText76')}}</div>
    <div class="otherList">
      <div class="type1" @click="clickShowFillIn('0')">
        {{$t('lang.BText56')}}
        <svg-icon icon-class="into"></svg-icon>
      </div>
      <div class="type1" @click="clickShowFillIn('1')">
        {{$t('lang.BText57')}}
        <svg-icon icon-class="into"></svg-icon>
      </div>
      <div class="type1" @click="clickShowFillIn('2')">
        {{$t('lang.BText58')}}
        <svg-icon icon-class="into"></svg-icon>
      </div>
      <div class="type1" @click="clickShowFillIn('3')">
        {{$t('lang.BText59')}}
        <svg-icon icon-class="into"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quotationMainType: "",
      quotationType: "",
      quotationTypeList: [],
      showType: [false, false, false, false],
      showType2: [false, false, false, false],
      showQuote: false,
    };
  },
  created() {
    let that = this;
    this.$session("quotationType").get((value) => {
      that.quotationTypeList = value;
      that.quotationTypeList.forEach((element) => {
        element.show = true;
      });
    });
  },
  methods: {
    clickType(e, item) {
      if (e > 0) {
        return;
      }
      this.showType[e] = true;
      this.quotationMainType = item.dictValue;
      this.quotationTypeList.forEach((item, index) => {
        if (index == e) {
          this.quotationType = item.children[0].dictValue;
          if (e != 0) {
            setTimeout(() => {
              this.quotationTypeList[index].show = false;
              this.$forceUpdate();
            }, 1000);
          }
          setTimeout(() => {
            this.showType2[e] = true;
            this.showQuote = true;
            this.$forceUpdate();
          }, 1350);
        } else if (index != e) {
          this.quotationTypeList[index].show = false;
          this.showType2[e] = false;
          this.$forceUpdate();
        }
      });
    },
    close() {
      this.showType = [false, false, false, false];
      this.showType2 = [false, false, false, false];
      this.showQuote = false;
      this.quotationTypeList.forEach((item, index) => {
        this.quotationTypeList[index].show = true;
      });
    },
    clickShowFillIn(e) {
      this.$emit("clickShowFillIn", e);
    },
    quote() {
      let that = this;
      if (that.quotationType == "") {
        that.$notify({
          title: this.$t('lang.SText126'),
          type: "warning",
          offset: 100,
        });
        return;
      }
      if (that.quotationType == "FCL") {
        this.$emit("chooseType", {
          quotationMainType: that.quotationMainType,
          quotationType: that.quotationType,
        });
        return;
      }
      that.$notify({
        title: this.$t('lang.SText127'),
        type: "warning",
        offset: 100,
      });
      return;
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/base.scss";
.booking-create {
  width: 860px;
  height: auto;
  color: $mainColor;
  margin-left: 210px;
  padding: 50px 0;
  .title {
    font-size: 30px;
    color: $mainColor;
    line-height: 64px;
    text-align: left;
  }
  .quotationType0-enter-active,
  .quotationType0-leave-active {
    transition: opacity 0.5s;
  }
  .quotationType0-enter,
  .quotationType0-leave-to {
    opacity: 0;
  }

  .quotationType1-enter-active,
  .quotationType1-leave-active {
    transition: opacity 1s;
  }
  .quotationType1-enter,
  .quotationType1-leave-to {
    opacity: 0;
  }

  .quotationType2-enter-active,
  .quotationType2-leave-active {
    transition: opacity 1.5s;
  }
  .quotationType2-enter,
  .quotationType2-leave-to {
    opacity: 0;
  }
  .quotationType3-enter-active,
  .quotationType3-leave-active {
    transition: opacity 2s;
  }
  .quotationType3-enter,
  .quotationType3-leave-to {
    opacity: 0;
  }

  .active1-enter-active,
  .active1-leave-active {
    // transition: opacity 1s;
    transition: transform 0.35s;
  }
  .active1-enter,
  .active1-leave-to {
    transform: translate(-220px, 0);
  }

  .active2-enter-active,
  .active2-leave-active {
    // transition: opacity 1s;
    transition: transform 0.35s;
  }
  .active2-enter,
  .active2-leave-to {
    transform: translate(-440px, 0);
  }
  .active3-enter-active,
  .active3-leave-active {
    // transition: opacity 1s;
    transition: transform 0.35s;
  }
  .active3-enter,
  .active3-leave-to {
    transform: translate(-660px, 0);
  }

  .hoverDiv-enter-active,
  .hoverDiv-leave-active {
    transition: opacity 0.5s;
  }
  .hoverDiv-enter,
  .hoverDiv-leave-to {
    opacity: 0;
  }

  .Quote {
    display: flex;
    text-align: center;
    justify-content: end;
    margin-right: 100px;
  }

  .title {
    font-size: 30px;
    color: $mainColor;
    line-height: 64px;
    text-align: left;
  }
  .typeList {
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 30px;
    // flex-wrap: wrap;
    position: relative;
    height: 200px;
    width: 900px;
    .type-icon-main {
      height: 200px;
      width: 200px;
      border: 2px solid $mainColor;
      position: absolute;
      margin-bottom: 10px;
      margin-right: 21px;
      .hoverType {
        background-color: $background;
        z-index: 99 !important;
      }

      .svg-icon {
        width: 100px;
        height: 100px;
        padding-top: 28px;
        fill: $mainColor;
        stroke: $mainColor;
      }
      .type-title {
        font-size: 18px;
        color: $mainColor;
        margin-top: 20px;
        line-height: 18px;
      }
    }
    .noneType {
      border: 2px solid $fontColor1;
      .type-title {
        color: $fontColor1 !important;
      }
      .svg-icon {
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
  }
  .type-item {
  }

  .children {
    position: absolute;
    left: 220px;
    right: 0;
    .radio {
      display: flex;
      align-items: center;
      height: 200px;
      .radio-item {
        margin: 0 20px;
      }
      .svg-icon {
        height: 100px;
        width: 100px;
        margin-right: 10px;
      }
      .left-icon {
        top: 30px;
        height: 50px;
        width: 50px;
        padding: 7px;
        color: #c0c0c0;
        border-radius: 32px;
        margin-left: 20px;
        position: absolute;
        right: 0;
        fill: #c0c0c0;
        stroke: #c0c0c0;
      }
      .left-icon:hover {
        background-color: #f9f9f9;
      }
    }
  }

  .or {
    width: 840px;
    border-bottom: 2px solid $mainColor;
    line-height: 60px;
    font-size: 30px;
    text-align: left;
    padding-left: 20px;
  }

  .otherList {
    text-align: left;
    line-height: 80px;
    font-size: 20px;
    width: 450px;
    .type1 {
      padding: 0 20px;
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer;

      .svg-icon {
        margin-left: 40px;
      }
    }
  }
}
</style>